<template>
    <v-row class="fill-height" >
        <v-col cols="12">
            <v-row>
                <v-col cols="12">
                    <v-card color="gry" class="pa-4 mx-2">
                        <BTSummary />
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-card color="gry">
                        <BTSummaryTableMobile />
                    </v-card>
                </v-col>

                <v-col cols="12" >
                    <v-card color="gry" class="pa-4">
                        <BTSummarySmall />
                    </v-card>
                </v-col>
            </v-row>    



        </v-col>
    </v-row>
</template>

<script>
import BTSummary from '@/components/DashBoard/parts/BTSummary'
import BTSummarySmall from '@/components/DashBoard/parts/BTSummarySmall'
import BTSummaryTableMobile from '@/components/DashBoard/parts/BTSummaryTableMobile'

    export default {
        name: 'MobileDashBoard',

        components: {
            BTSummary,
            BTSummarySmall,
            BTSummaryTableMobile
        },

    }
</script>
