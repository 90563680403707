import store from '@/store'

export default (to, from, next) => {
    const user = store.state.user

    if(user){      
        next()
    } else {
        next('/')
    }
}