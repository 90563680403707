<template>
    <v-row class="" no-gutters>

        <v-col cols="9" class="">
            <v-card color="gry" class="pb-4 ml-3 mr-3">
                <BTSummaryTable />
            </v-card>
        </v-col>

        <v-col cols="3" >
            <v-row no-gutters class="mb-4">
                <v-col cols="12">
                    <v-card color="gry" class="fill-height pa-4">
                        <DBActions />
                    </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-4">
                <v-col cols="12">
                    <v-card color="gry" class="fill-height pa-4">
                        <BTSummary />
                    </v-card>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="12">
                    <v-card color="gry" class="pa-4">
                        <BTSummarySmall />
                    </v-card>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import DBActions from '@/components/DashBoard/parts/DBActions'
import BTSummary from '@/components/DashBoard/parts/BTSummary'
import BTSummarySmall from '@/components/DashBoard/parts/BTSummarySmall'
import BTSummaryTable from '@/components/DashBoard/parts/BTSummaryTable'

    export default {
        name: 'DeskTopDashBoard',

        components: {
            DBActions,
            BTSummary,
            BTSummarySmall,
            BTSummaryTable
        },

    }
</script>
