<template>
        <v-row no-gutters align="center" >
            <v-col cols="3">
                <v-slider dense hide-details :thumb-color="tail.thumb" color="grey" track-color="red" v-model="tail.val" />
            </v-col>
            <v-col cols="4">
                <v-sheet width="2" color="buy" />
                <v-sheet color="overGry" class="rounded">
                    <v-slider dense hide-details :thumb-color="box.thumb" :color="box.track" track-color="grey" v-model="box.val"/>
                </v-sheet>
            </v-col>
            <v-col cols="3">
                <v-slider dense hide-details :thumb-color="head.thumb" color="sell" track-color="grey" v-model="head.val" />
            </v-col>
            <v-col cols="2">
                <p class="text-center caption mb-0">{{ currentPercent }} %</p>
            </v-col>
        </v-row>
</template>

<script>
export default {
    name:'PotSummaryChart',

    props:['vals'],

    data: () => ({
        tail:{
            val: 0,
            thumb: 'transparent',
        },
        box:{
            val: 0,
            thumb: 'transparent',
            track: 'green'
        },
        head:{
            val: 0,
            thumb: 'transparent',
        }

    }),

    computed:{

        currentPercent(){
            if(this.vals){
                const pct = ((((this.vals.rate-this.vals.buy)) / ((this.vals.rate + this.vals.buy)/2))*100)
                this.setVals(pct)
                this.setThumbs(pct)
                return pct.toFixed(2)
            }
        },

    },

    methods:{
        sellPercent(p){
            const pct = ((p / this.vals.pct)*100)
            // console.log(pct)
            return pct
        },

        setVals(p){
            let a = []
            if(p < 0){
                a = [100+p, 0, 0]
            } else if(p > this.vals.pct){
                a = [100, 100, p*10]
            } else {
                a = [100, this.sellPercent(p), 0]
            }
            this.tail.val = a[0]
            this.box.val = a[1]
            this.head.val = a[2]
            console.log(this.box.val)
        },

        setThumbs(p){
            let a = []
            if(p < 0){
                a = ['red', 'transparent', 'green', 'transparent' ]
            } else if(p > this.vals.pct){
                a = ['transparent', 'transparent', 'sell', 'sell' ]
            } else {
                a = ['transparent', 'green', 'green', 'transparent' ]
            }
            this.tail.thumb = a[0]
            this.box.thumb = a[1]
            this.box.track = a[2]
            this.head.thumb = a[3]
        }
    }
}
</script>