<template>
    <div>
        <v-row v-if="transactions" no-gutters>
            <v-col cols="12">
                <v-row >
                    <v-col cols="12">
                        <p :class="`${sizes.text} font-weight-bold buy--text mb-1`">Buys</p>
                    </v-col>
                </v-row>
                <v-card  color="transparent" class="OverflowCardY" :height="height">
                    <v-card v-for="(s,i) in transactions" :key="i" class="mb-1" :color="age(s).c">
                        <v-row align="center" no-gutters>
                            <v-col cols="1" class="px-2">
                                <v-avatar :size="sizes.avatar" color="white" ><v-img :src="s.ico" /></v-avatar>
                            </v-col>
                            <v-col cols="4">
                                <p :class="`${sizes.smallText} mb-0`">{{ s.pot}}</p>
                            </v-col>
                            <v-col cols="2" md="2">
                                
                            </v-col>
                            <v-col cols="5">
                                <v-row no-gutters justify="end">
                                    <v-col cols="12" md="4">
                                        <p :class="`${sizes.smallText} text-right mb-0`">{{ parseFloat(s.comission).toFixed(3) }}<v-icon right class="mb-1" color="pink" :small="sizes.smallIcon">mdi-cash-refund</v-icon></p>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <p :class="`${sizes.smallText} text-right mb-0`">{{ age(s).str }}<v-icon right class="mb-1" color="sell" :small="sizes.smallIcon">mdi-clock-outline</v-icon></p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>


        <v-row v-else>
            <v-col cols="12">
                <p class="text- caption">Getting Data</p>
                <v-progress-linear indeterminate height="1" class="px-4" color="primary" />
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'summarySellTransactions',

    components: {

    },

    data: () => ({

    }),

    computed:{
        transactions(){
            return this.$store.getters.transactionBuys
        },

        sizes(){
            return this.$store.getters.sizes
        },

        height(){
            return this.$vuetify.breakpoint.smAndDown ? null : 168
        }
    },
    
    methods:{
        age(s){
            const h = Math.abs(s.time.hours)
            const m = Math.abs(s.time.minutes)
            const str = `${h.toFixed(0)}h ${m.toFixed(0)}m` 
            const c = h == 0 && m < 6 ? 'green darken-3' : 'underGry'
            return {str, c}
        }
    }
}
</script>