<template>
  <div>
    <v-row v-if="symbols" no-gutters>
      <v-col cols="12">
        <v-card v-if="sizes.coinPerformanceTitle" color="transparent" flat tile class="px-3 pt-3 pb-2">
          <p :class="`${sizes.text} font-weight-bold green--text mb-0`">Coin Performance</p>
        </v-card>
        <v-card color="transparent" flat class="OverflowCardY pr-1" :height="height">
          <v-row v-for="s in sortedSymbols" :key="s.symbol" no-gutters class="mb-1">
            <v-col cols="12">
              <summaryCoinLine :symbol="s" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
          <p class="text- caption">Getting Data</p>
          <v-progress-linear indeterminate height="1" class="px-4" color="primary" />
      </v-col>
    </v-row>
    
    </div>
</template>

<script>
import summaryCoinLine from '@/components/V2/DashBoard/Widgets/summaryCoinLine'

export default {
  name: 'SummaryCoinPerformance',

  components: {
    summaryCoinLine,
  },

  data: () => ({
    sortBy: 'EstimatedDeltaValue'
  }),

  computed:{
    symbols(){
      return this.$store.getters.bittrexSymbols  
    },

    sortedSymbols(){
      if(this.symbols){
        return this.symbols.sort((a,b) => a.data[this.sortBy]-b.data[this.sortBy]).reverse()
      } else {
        return []
      }
    },

    sizes(){
      return this.$store.getters.sizes
    },

    height(){
      return this.$vuetify.breakpoint.smAndDown ? null : this.$vuetify.breakpoint.height - 130
    }
  }
}
</script>

