<template>
    <div class="fill-height">
        <component v-if="currentCoinData && currentCoinHistory && !coins" :is="component" @onDashboard="onDashboard" />
        <v-row v-else-if="coins" class="fill-height" justify="center" align="center" no-gutters>
            <v-col cols="auto">
                <v-card max-width="500" color="gry" class="pa-4">
                    <p>There are more than one coins with this symbol, please select the correct coin?</p>
                    <v-row v-for="c in coins" :key="c.id"  align="center">
                        <v-col cols="3">
                            <p class="text-caption grey--text mb-0">Symbol:</p>
                            <p class="text-caption grey--text mb-0">Name:</p>
                            <p class="text-caption grey--text mb-0">Id:</p>
                        </v-col>
                        <v-col cols="6">
                            <p class="text-caption mb-0">{{ c.symbol }}</p>
                            <p class="text-caption mb-0">{{ c.name }}</p>
                            <p class="text-caption mb-0">{{ c.id }}</p>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" @click="onSetCurrentCoin(c)">Select</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="fill-height" justify="center" align="center">
            <v-col cols="auto">
                <v-progress-circular  class="mx-3" color="primary" width="2" indeterminate /><span class="text-caption grey--text">Getting Coin Data</span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CoDesktop from '@/components/Coin/Desktop'
import CoMobile from '@/components/Coin/Mobile'
import CoNone from '@/components/Coin/CoNone'

export default {
    name: 'CoinDashboard',

    components: {
        CoDesktop,
        CoMobile,
        CoNone
    },

    data: () => ({
        coinDataInterval: null,
        coinRatesInterval: null,
        coins: null
    }),

    computed:{
        ...mapState({
            coinList: state => state.coinList,
            currentCoin: state => state.currentCoin,
            currentCoinData: state => state.currentCoinData,
            currentCoinHistory: state => state.currentCoinHistory
        }),

        coin(){
            return this.$route.params.id
        },

        component(){
            if(this.coin){
                return this.$vuetify.breakpoint.mobile ? 'CoMobile' : 'CoDesktop'
            } else {
                return 'CoNone'
            }
        }        
    },

    methods:{
        onFetchCoin(){
            const potSetup = {api:'potSetup', mutation:'SET_POT_SETUP', params:`currency=${this.coin}` }
            const coinList = {api:'https://api.coingecko.com/api/v3/coins/list', mutation:'SET_COIN_LIST'}

            this.$store.dispatch('getAPIData', coinList)
            this.$store.dispatch('getAPIDataV2', potSetup)
        },


        onFetchCoinData(v){
            this.$store.dispatch('getAPIData', {api:`https://api.coingecko.com/api/v3/coins/${v.id}?localization=en&tickers=true&market_data=true`, mutation:'SET_COIN_DATA_FROM_ID'})
            this.$store.dispatch('getAPIData', {api:`https://api.coingecko.com/api/v3/coins/${v.id}/market_chart?vs_currency=usd&days=30`, mutation:'SET_COIN_HISTORY_FROM_ID'})
            this.$store.dispatch('getAPIDataV2', {api:'resetPot', mutation:'SET_COIN_CURRENCY', params:`Action=GetCurrency&Currency=${this.coin}`})
        },

        onFetchCoinRates(v){
            this.$store.dispatch('getAPIDataV2', {api:'resetPot', mutation:'SET_COIN_RATE', params:`Action=GetRate&Currency=${this.coin}`})
        },

        onSetCurrentCoin(cc){
            this.$store.dispatch('setCurrentCoin', cc)
            this.coins = null
        },

        onDashboard(){
            clearInterval(this.coinDataInterval)
            clearInterval(this.coinRatesInterval)
            this.$store.dispatch('clearCoinData')
        },
    },

    created(){
        this.onFetchCoin()
    },

    watch:{
        coinList(l){
            const cid = this.coinList.filter(c => c.symbol.toLowerCase() == this.coin.toLowerCase())
            if(cid.length == 1){
                this.onSetCurrentCoin(cid[0])
            } else if(cid.length > 1){
                this.coins = cid
            }
        },

        currentCoin(v){
            this.onFetchCoinData(v)
            this.onFetchCoinRates(v)

            this.coinDataInterval = setInterval(() => {
                this.onFetchCoinData(v)
            }, 60000)

            this.coinRatesInterval = setInterval(() => {
                this.onFetchCoinRates(v)
            }, 10000)
        }
    }
}
</script>