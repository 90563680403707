<template>
    <div>
        
        <v-row class="" no-gutters>

            <v-col cols="3" class="pl-1">
                <v-btn x-small block class="overGry" @click="onChangeSort('Currency')">Source</v-btn>
            </v-col>
            <v-col cols="3" class="pl-1">
                <v-btn x-small block class="overGry" @click="onChangeSort('SourceValue')">Coins</v-btn>
            </v-col>
            <v-col cols="3" class="pl-1">
                <v-btn x-small block class="overGry" @click="onChangeSort('TotalPots')">Pots</v-btn>
            </v-col>
            <v-col cols="3" class="pl-1">
                <v-btn x-small block class="overGry" @click="onChangeSort('EstimatedDeltaValue')">Delta</v-btn>
            </v-col>
        </v-row>

        <v-divider class="mb-4" />

        <div v-if="coins">

            <v-row v-for="(c,i) in sortedCoins" :key="c.Currency" align="center" no-gutters >
                <v-col cols="3" >
                    <v-row align="center" no-gutters>
                        <v-col cols="4">
                            <v-avatar v-if="coinIcon(c.Currency)" size="18" class="ml-2"><v-img  :src="coinIcon(c.Currency)" contain /></v-avatar>
                            <v-avatar v-else size="18" class="ml-2"><cryptoicon :symbol="c.Currency" size="18" color="white" /></v-avatar>
                        </v-col>
                        <v-col cols="8" >
                            <v-btn block x-small @click=(onViewCoin(c)) color="overGry" class="ml-1 mt-1" >{{ c.Currency }}</v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="3">
                    <p class="text-caption text-right mb-0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="onDiscrepancy(c).trigger" small left color="orange" dark v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
                            </template>
                            <span>{{ onDiscrepancy(c).str }}</span>
                        </v-tooltip>
                        {{ c.SourceValue.toFixed(2) }}
                    </p>
                </v-col>

                <v-col cols="3">
                    <p :class="`${potStr(c).i}--text text-caption text-right mb-0 pr-6`">{{ potStr(c).s }}</p>
                </v-col>

                <v-col cols="3">
                    <v-card :class="`mx-1 ${c.EstimatedDeltaValue > 0 ? 'CnGreenGrad' : 'CnRedGrad'}`">
                        <p class="text-caption text-center mb-0">{{ c.EstimatedDeltaValue.toFixed(2) }}</p>
                    </v-card>
                </v-col>
            </v-row>

        </div>
        
        <v-row v-else justify="center">
            <v-col cols="10">
                <v-progress-linear indeterminate  color="primary" height="1"  />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { mapState } from 'vuex'

    export default {
        name: 'SummaryTableMobile',

        data: () => ({
            changeIcon: 'mdi-pan-vertical',
            sortBy: 'Currency',
            sortAsc: true
        }),

        computed:{
            ...mapState({
                BS: state => state.bittrexSummary,
                BSL: state => state.bittrexSummaryLast,
                settings: state => state.settings,
                coinIcons: state => state.coinicons
            }),

            coins(){
                if(this.settings && this.BS){
                    return this.BS.filter(i => !this.settings.excludedCoins.includes(i.Currency))
                } else {
                    return null
                }
            },

            sortedCoins(){
                let arr = []
                if(this.sortBy == 'Currency'){
                    arr = this.coins.sort((a,b) => a[this.sortBy] < b[this.sortBy] ? -1 : a[this.sortBy] > b[this.sortBy] ? 1 : 0)
                } else {
                    arr = this.coins.sort((a,b) => a[this.sortBy]-b[this.sortBy])
                }

                if(this.sortAsc){
                    return arr
                } else {
                    return arr.reverse()
                }
            },

            oldCoins(){
                if(this.settings && this.BSL){
                    return this.BSL.filter(i => !this.settings.excludedCoins.includes(i.Currency))
                } else {
                    return null
                }
            },
        },

        methods:{
            potStr(c){
                const p = c.TotalPots
                const u = ((c.TotalPots * c.TotalInvestedPercent)/100).toFixed(0)
                const s = `${u} of ${p}`
                const i = u == p ? 'green' : 'secondary'
                return {s, i}
            },

            potChange(i, k){
                if(this.oldCoins){
                    const diff = this.coins[i][k] / this.oldCoins[i][k]
                    if(diff == 1){
                        return {c:'grey darken-2', i:'mdi-arrow-right'}
                    } else if(diff > 1){
                        return {c:'green lighten-2', i:'mdi-arrow-up'}
                    } else {
                        return {c:'red lighten-2', i:'mdi-arrow-down'}
                    }
                } else {
                    return 'grey'
                }
            },
        
            coinIcon(c){
                if(this.coinIcons){
                    const i = this.coinIcons.icons.filter(ci => ci.ident.toUpperCase() == c.toUpperCase())[0]
                    if(i){
                        return i.ref
                    }
                }
            },

            onDiscrepancy(c){
                const diff = c.SourceValue > 0 ? ((c.Discrepancy / c.SourceValue)*100).toFixed(2) : 0
                const str = `${diff} % Discrepancy`
                const trigger = Math.abs(diff) > 1 
                return {diff, str, trigger}
            },

            onViewCoin(c){
                this.$router.push(`/coin/${c.Currency}`)
            },

            onChangeSort(hdg){
                this.sortBy = hdg
                this.sortAsc = !this.sortAsc
                console.log(this.sortBy, this.sortAsc)
            }
    },
}
</script>
