<template>
    <div>
        <v-row>
            <v-col cols="12">
                <p :class="`${sizes.text} font-weight-bold green--text mb-1`">Transactions</p>
            </v-col>
        </v-row>
        <v-row  v-for="t in transactions" :key="t.txt" justify="center" no-gutters>
            <v-col cols="auto">
                <p :class="`${sizes.smallText} font-weight-bold mb-1`">{{ t.txt }}</p>
            </v-col>
            <v-spacer />
            <v-col cols="10">
                <v-progress-linear rounded  background-color="sell" color="buy" :height="sizes.potBar" :value="t.val" class="mt-2" />
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'summaryTransactions',

    components: {

    },

    data: () => ({

    }),

    computed:{
        sizes(){
            return this.$store.getters.sizes
        },

        bss(){
            return this.$store.getters.BittrexSummarySmallFormatted
        },

        valRatio(){
            if(this.bss){
                const tb = parseFloat(this.bss.usdBuyVal) + parseFloat(this.bss.usdtBuyVal)
                const ts = parseFloat(this.bss.usdSellVal) + parseFloat(this.bss.usdtSellVal)
                return ((tb)/(tb+ts))*100
            }
        },

        countRatio(){
            if(this.bss){
                const tb = parseFloat(this.bss.usdBuyCount) + parseFloat(this.bss.usdtBuyCount)
                const ts = parseFloat(this.bss.usdSellCount) + parseFloat(this.bss.usdtSellCount)
                return ((tb)/(tb+ts))*100
            }
        },

        cryptoRatio(){
            if(this.bss){
                return (this.bss.totalUSD/this.bss.totalValue)*100
            }
        },

        potRatio(){
            const p = this.$store.getters.potBreakdown
            if(p){
                return (p.buy.length/p.active.length)*100
            }
        },


        transactions(){
            return [
                {txt: 'Value', val: this.valRatio},
                {txt: 'Count', val: this.countRatio},
                {txt: 'Crypto', val: this.cryptoRatio},
                {txt: 'Pots', val: this.potRatio},
            ]
        }

    }        

}
</script>