<template>
    <div>
        <v-row v-if="bss" no-gutters>
            <v-col cols="12">
                <v-row class="mb-4" no-gutters>
                    <v-col cols="12">
                            <v-row no-gtters>
                                <v-col cols="12">
                                    <p :class="`${sizes.text} font-weight-bold primary--text mb-0`">Bittrex Summary</p>
                                </v-col>
                            </v-row>

                            <v-row v-for="(b,i) in bittrexData" :key="i" align="end" no-gutters>
                                <v-col v-if="b.divider" cols="12">
                                    <v-divider  class="px-2 "/>
                                </v-col>
                                <v-col cols="auto">
                                    <p :class="`${sizes.smallText} grey--text mb-0`">{{ b.txt }}</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="8">
                                    <p :class="`text-right ${sizes.text} mb-0`">
                                        <span v-if="b.d">$</span>
                                        {{ b.val }}
                                        <span v-if="b.p">%</span>
                                    </p>
                                </v-col>
                            </v-row>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12">
                <p class="text- caption">Getting Data</p>
                <v-progress-linear indeterminate height="1" class="px-4" color="primary" />
            </v-col>
         </v-row>

    </div>
</template>

<script>

export default {
    name: 'BittrexSummaryData',

    props:['size'],

    computed:{
        bss(){
            return this.$store.getters.BittrexSummarySmallFormatted
        },

        bittrexData(){
            return [
                {txt:'Total USD', val:this.bss.totalUSD,  d:true},
                {txt:'Total Crypto', val:this.bss.totalCrypto,  d:true},
                {txt:'Total Value', val:this.bss.totalValue,  d:true, divider:true},
                {txt:'Available to Invest', val:this.bss.availableToInvest,  d:true},
                {txt:'Total Credit', val:this.bss.totalCRD},
                {txt:'Total Stake', val:this.bss.totalStake,  d:true},
                {txt:'Invested', val:this.bss.totalInvested,  d:true, divider: true},
                {txt:'', val:this.bss.investedPercentage,  p:true},
            ]
        },

        sizes(){
            return this.$store.getters.sizes
        }
    }
}
</script>