import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import DashboardV2 from '../views/V2/Dashboard.vue'
import Coin from '../views/Coin.vue'

import inGuard from '@/router/in.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardV2,
    beforeEnter: inGuard
  },

  {
    path: '/coin/:id',
    name: 'Coin',
    component: Coin,
    beforeEnter: inGuard
  },

  { path: '*', 
    beforeEnter: inGuard
  }
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
