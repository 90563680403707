<template>
    <div>
        <v-row v-if="bss" no-gutters>
            <v-col cols="12">
                <v-row class="mb-4" no-gutters>
                    <v-col cols="12">

                            <v-row no-gtters>
                                <v-col cols="12">
                                    <p :class="`${sizes.text} font-weight-bold green--text mb-0`">Today</p>
                                </v-col>
                            </v-row>

                            <v-row v-for="t in today" :key="t.txt" align="end" no-gutters>
                                <v-col cols="auto">
                                    <p :class="`${sizes.smallText} grey--text mb-0`">{{ t.txt }}</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="5">
                                    <p :class="`text-right ${sizes.text} mb-0`">
                                        <span v-if="t.d">$</span>
                                        {{ t.val }}
                                    </p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-icon :small="sizes.smallIcon" :color="t.col">mdi-ray-start-arrow</v-icon>
                                </v-col>
                                <v-col cols="2">
                                     <p :class="`text-right ${sizes.text} mb-0`">{{ t.count }}</p>
                                </v-col>
                            </v-row>

                    </v-col>
                </v-row>


                <v-row class="mb-4" no-gutters>
                    <v-col cols="12">
                        <v-row no-gtters>
                            <v-col cols="12">
                                <p :class="`${sizes.text} font-weight-bold sell--text mb-0 mt-2`">Profit</p>
                            </v-col>
                        </v-row>

                        <v-row v-for="p in profit" :key="p.txt" align="end" no-gutters>
                            <v-col cols="auto">
                                <p :class="`${sizes.smallText} grey--text mb-0`">{{ p.txt }}</p>
                            </v-col>
                            <v-spacer />
                            <v-col cols="8">
                                <p :class="`text-right ${sizes.text} mb-0`">
                                    <span v-if="p.d">$</span>
                                    {{ p.val }}
                                </p>
                            </v-col>
                        </v-row>
                    
                    </v-col>
                </v-row>

                <v-row  no-gutters>
                    <v-col cols="12">
                        <v-row no-gtters>
                            <v-col cols="12">
                                <p :class="`${sizes.text} font-weight-bold accent--text mb-0 mt-4`">Crypton</p>
                            </v-col>
                        </v-row>

                        <v-row v-for="c in crypton" :key="c.txt" justify="end" no-gutters>
                            <v-col cols="3">
                                <p :class="`${sizes.smallText} grey--text mb-0`">{{ c.txt }}</p>
                            </v-col>
                            <v-spacer />
                            <v-col v-if="c.t2" cols="8">
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <p :class="`text-right ${sizes.smallText} mb-0 ${c.c2}--text`">{{ c.t2 }}</p>
                                    </v-col>
                                    <v-col cols="8">
                                        <p :class="`text-right ${sizes.text} mb-0`">
                                            <span v-if="c.d2">$</span>
                                            {{ c.val2 }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="8">
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <p :class="`text-right ${sizes.smallText} mb-0 ${c.c1}--text`">{{ c.t1 }}</p>
                                    </v-col>
                                    <v-col cols="8">
                                        <p :class="`text-right ${sizes.text} mb-0`">
                                            <span v-if="c.d1">$</span>
                                            {{ c.val1 }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12">
                <p class="text- caption">Getting Data</p>
                <v-progress-linear indeterminate height="1" class="px-4" color="primary" />
            </v-col>
         </v-row>
    </div>
</template>

<script>

export default {
    name: 'BittrexSummary',

    props: ['size'],

    computed:{
        bss(){
            return this.$store.getters.BittrexSummarySmallFormatted
        },

        pots(){
            return this.$store.getters.potBreakdown
        },

        today(){
            return [
                {txt:'USD Buys', val:this.bss.usdBuyVal, count:this.bss.usdBuyCount, col:'buy', d:true},
                {txt:'USDT Buys', val:this.bss.usdtBuyVal, count:this.bss.usdtBuyCount, col:'buy', d:true},
                {txt:'USD Sells', val:this.bss.usdSellVal, count:this.bss.usdSellCount, col:'sell', d:true},
                {txt:'USDT Sells', val:this.bss.usdtSellVal, count:this.bss.usdtSellCount, col:'sell', d:true},
            ]
        },

        profit(){
            return [
                {txt:'USD Today', val:this.bss.usdToday, d:true},
                {txt:'USDT Today', val:this.bss.usdtToday, d:true},
                {txt:"Today's Total", val:this.bss.totalToday, d:true},
                {txt:'USD Total', val:this.bss.usdTotal, d:true},
                {txt:'USDT Total', val:this.bss.usdtTotal, d:true},
            ]
        },

        crypton(){
            return [
                {txt:'Total Stake', val1:this.bss.botStakeValue, d1:true},
                {txt:'Total Coins', val1:this.bss.coinCount},
                {txt:'pots', t1:'total:', val1:this.pots.total.length, t2:'active:', val2:this.pots.active.length},
                {txt:'Float', t1:'Available:', val1:this.bss.botAvailableFloat, d1:true, c1:'green', t2:'cooldown:', val2:this.bss.botCooldownValue, d2:true, c2:'blue'},
            ]
        },

        sizes(){
        return this.$store.getters.sizes
        }
    }
}
</script>