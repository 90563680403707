<template>
    <div>
        <v-card color="transparent" v-if="coins">
            <v-card color="transparent" flat tile class="pl-3">
                <v-row align="center" no-gutters>
                    <v-col cols="auto">
                        <p :class="`${sizes.text} font-weight-bold green--text mb-0`">Coin Ranking</p>
                    </v-col>
                    <v-spacer />
                        <v-col cols="auto">
                        <p :class="`${sizes.smallText} mb-0`"><span class="grey--text">Days: </span>{{ coinPerformanceDuration }}</p>
                    </v-col>
                </v-row>
            </v-card>
            <v-card color="transparent" flat class="OverflowCardY px-3" :height="height" >
                <v-card color="transparent" flat tile class="pb-4">
                    <v-row justify="center" no-gutters>
                        <v-expansion-panels accordion>
                            <v-expansion-panel class="transparent">
                                <v-expansion-panel-header>
                                    <p :class="`${sizes.smallText} grey--text mb-0`">Change Duration</p>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-text-field v-model="dur" outlined dense append-outer-icon="mdi-update" @click:append-outer="onUpdateDuration"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-card>

                <v-row v-for="(c,i) in sortedCoins" :key="i" no-gutters class="mb-1">
                    <v-col cols="2">
                        <v-avatar :size="sizes.avatar" color="white" ><v-img :src="icon(c.SourceCurrency)" /></v-avatar>
                    </v-col>
                    <v-col cols="3">
                        <p :class="`${sizes.smallText} grey--text mb-0`">{{ c.SourceCurrency}}</p>
                    </v-col>
                    <v-col cols="4">
                        <p :class="`${sizes.text} text-right mb-0`">${{ parseFloat(c.Profit).toFixed(2)}}</p>
                    </v-col>
                    <v-col cols="3">
                        <p :class="`${sizes.smallText} text-right mb-0`">{{ c.SellCount}}</p>
                    </v-col>
                </v-row>
            </v-card>
        </v-card>

        <v-row v-else>
            <v-col cols="12">
                <p class="text- caption">Getting Data</p>
                <v-progress-linear indeterminate height="1" class="px-4" color="primary" />
            </v-col>
        </v-row>
    
    </div>
</template>

<script>
import summaryCoinLine from '@/components/V2/DashBoard/Widgets/summaryCoinLine'

export default {
    name: 'SummaryCoinPerformance',

    components: {
        summaryCoinLine,
    },

    data: () => ({
        dur: 2
    }),

    computed:{
        coinPerformanceDuration(){
            return this.$store.state.coinPerformanceDuration
        },

        coins(){
            // console.log(this.$store.state.coinPerformance)
            return this.$store.state.coinPerformance  
        },

        sortedCoins(){
            if(this.coins){
            return this.coins.sort((a,b) => a.Profit-b.Profit).reverse()
            } else {
            return []
            }
        },

        screenHeight(){
            return this.$vuetify.breakpoint.height - 125
        },

        sizes(){
            return this.$store.getters.sizes
        },
        
        height(){
            return this.$vuetify.breakpoint.smAndDown ? null : 368
        }
    },

    methods:{
        onUpdateDuration(){
            if(this.dur > 0){
                this.$store.dispatch('updateCoinPerformanceDuration', this.dur)
            }
        },

        icon(s){
            return this.$store.getters.symbolIcon(s)
        },
    }
}
</script>

