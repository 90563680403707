<template>
    <div>
        <v-card color="gry" class="px-2">
            <v-row>
                <v-col cols="4">
                    <v-btn block class="grey" @click="addPotConfirm = true">Add New Pot</v-btn>
                </v-col>
                <v-col cols="4">
                    <v-btn block class="primary" @click="manualResetConfirm = true">Manual Reset</v-btn> 
                    <!-- <p class="text-caption grey--text mb-0">Single Attempt to reset every pot for the stake value</p> -->
                </v-col>
                <v-col cols="4">
                    <v-btn block class="secondary" @click="autoResetConfirm = true">Auto Reset</v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="manualResetConfirm" persistent width="600">
            <ManualReset :resetAllowed="resetAllowed" :resetParams="resetParams" @close="manualResetConfirm = false" />
        </v-dialog>

        <v-dialog v-model="autoResetConfirm" persistent width="600">
            <AutoReset :resetAllowed="resetAllowed" :resetParams="resetParams" @close="autoResetConfirm = false" />
        </v-dialog>

        <v-dialog v-model="addPotConfirm" persistent width="600">
            <addPot :resetAllowed="resetAllowed" :resetParams="resetParams" @close="addPotConfirm = false" />
        </v-dialog>
    </div>


</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import ManualReset from '@/components/Coin/parts/ManualReset'
import AutoReset from '@/components/Coin/parts/AutoReset'
import addPot from '@/components/Coin/parts/addPot'

export default {
    name: 'CoinControls',

    components: {
        ManualReset,
        AutoReset,
        addPot
    },

    data: () => ({
        manualResetConfirm: false,
        autoResetConfirm: false,
        addPotConfirm: false,
    }),

    computed:{
        ...mapState({
            coinCurrency: state => state.coinCurrency,
            coinRate: state => state.coinRate,
            resetResponse: state => state.resetResponse,
            settings: state => state.settings
        }),

        coin(){
            return this.$route.params.id
        },

        resetParams(){
            if(this.coinCurrency && this.coinRate){
                const r = this.coinCurrency[0]
                const invested = parseFloat(r.StakeValue - r.DestinationValue)
                const stake = parseFloat(r.StakeValue)
                const source = parseFloat(r.SourceValue)
                const rate = source > 0 ? (invested / source) : ''
                const ask = this.coinRate[0].Rate
                const api = 'resetPot'
                const params = `Action=TriggerSell&SourceCurrency=${r.SourceCurrency}-${r.DestinationCurrency}&MinimumSell=${invested}&StakeValue=${stake}&SourceValue=${source}&Rate=${rate}` 
                return {invested, stake, source, rate, params, api, ask}
            }
        },

        resetAllowed(){
            if(this.resetParams){
                return this.resetParams.rate < this.coinRate[0].Rate
            } else {
                return false
            }
        }
    },

    methods:{
        dtSQL(s){
            const dt = DateTime.fromSQL(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
            return dt
        },

        onResetResponse(){
            this.response = 'Resting...'
            this.col = 'white'
        },

        onManualResetCoin(){
            if(this.resetParams){
                if(this.resetAllowed){
                    this.$store.dispatch('getAPIDataV2', {api:this.resetParams.url, mutation:'SET_RESET_RESPONSE'})
                } else {
                    this.col = 'cyan'
                    this.response = 'It is too shameful to submit a loss to Crypton, sorry!'
                }
            } else {
                this.col = 'red'
                this.response = 'Failed'
                this.subResponse = 'Coin Parameters incomplete...'
            }
        },

        onAutoResetCoin(){
            if(this.settings){
                this.autoInterval = setInterval(() => {
                    if(this.autoReset){

                    } else {
                        this.col = 'orange'
                        this.response = 'Stopped'
                        this.subResponse = 'You stopped the auto reset function.'  
                    }

                }, this.settings.autoResetInverval)
            } else {
                this.col = 'red'
                this.response = 'Failed'
                this.subResponse = 'Reset Interval not Available.'   
            }
        },

        onManualReset(){
            this.manualResetConfirm = false
            this.onManualResetCoin()
        },

        onAutoReset(){
            this.autoResetConfirm = false
            this.autoReset = true
            this.onAutoResetCoin()
        }

    },

    watch:{
        resetResponse(v){
            if(v.length > 0){
                this.response = v[v.length - 1].Result
                this.subResponse = v[v.length - 1].LogLine
                this.col = this.response == 'Success' ? 'green' : 'red'
            }
        }
    }

}
</script>