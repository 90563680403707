<template>
  <div class="fill-height" >
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="10" md="4" xl="3">

          <v-card v-if="user"  color="transparent" flat>
            <p class="text-center"><v-btn outlined x-small color="grey darken-3" @click="onDashboard">Dashboard</v-btn></p>
          </v-card>

          <v-card v-else-if="user == null"  color="transparent" flat>
                <v-progress-linear indeterminate rounded color="primary" height="1" />
          </v-card>


          <v-card v-if="user == false" class=" gry pa-4">
            <p>Login</p>
            <v-text-field v-model="email" label="E-mail" type="text" dense outlined/>
            <v-text-field v-model="password" label="Password" type="password" dense outlined/>
            <p class="text-caption error--text">{{ authMsg }}</p>
            <v-row justify="end" no-gutters>
              <v-btn outlined small color="accent" @click="onLogin">Login</v-btn>
            </v-row>
          </v-card>
        
        
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  name: 'Home',

  data: () => ({
    email: '',
    password: ''
  }),

  computed:{
    ...mapState({
      user: state => state.user,
      apis: state => state.apis,
      authMsg: state => state.authMsg
    })
  },

  methods:{
    onLogin(){
      this.$store.dispatch('loginUsr', {email:this.email, password:this.password})
    },

    onDashboard(){
      this.$router.push('/dashboard')
    }
  },

  watch:{
    apis(val){
      if(val){
        this.onDashboard()
      }
    }
  }
}
</script>

