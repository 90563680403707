<template>
    <div>
        <v-row v-if="status == 'Buy'" align="center">
            <v-col cols="4">
                <p class="grey--text text-caption mb-0 mt-1">{{ buyAction.txt }}</p>
            </v-col>
            <v-col v-if="buyAction.details" cols="auto">
                <p class="grey--text text-caption mb-0 mt-1"><v-icon small left class="mb-1" color="buy">mdi-cash</v-icon> Last Sold @ {{ p.lastAskRate }}</p>
            </v-col>
            <v-spacer />
            <v-col v-if="buyAction.details" cols="auto">
                <p v-if="p.cooldown.past" class="blue--text text-caption mb-0 mt-1 mr-2">{{ (p.cooldown.minutes).toFixed(0) }}<v-icon small right class="mb-1" color="blue">mdi-snowflake</v-icon></p>
                <p v-else class="grey--text text-caption mb-0 mt-1 mr-2"> {{ Math.abs(p.cooldown.days) }}d {{ Math.abs(p.cooldown.hours) }}h {{Math.abs((p.cooldown.minutes).toFixed(0)) }}m <v-icon small right class="mb-1" color="buy">mdi-binoculars</v-icon></p>
            </v-col>
        </v-row>

        <v-row v-else no-gutters align="center">
            <v-col cols="9">
                <PotSummaryChart :vals="vals" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <p class="grey--text text-caption mb-0 mt-1 mr-2"> {{ Math.abs(p.sellTime.days) }}d {{ Math.abs(p.sellTime.hours) }}h {{Math.abs((p.sellTime.minutes).toFixed(0)) }}m <v-icon small right class="mb-1" color="sell">mdi-binoculars</v-icon></p>
            </v-col>
        </v-row>

    </div>


</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import PotSummaryChart from '@/components/parts/PotSummaryChart'

export default {
    name: 'PotSummaryGraphic',

    props:['pot'],

    components: {
        PotSummaryChart
    },

    data: () => ({
        now: null,
        int:null
    }),

    computed:{
        ...mapState({
            coinRate: state => state.coinRate,
        }),

        status(){
            return this.pot.SourceValue > 0 ? 'Sell' : 'Buy'
        },

        p(){
            if(this.pot){
                return {
                    lastAskRate: parseFloat(this.pot.LastAskRate).toFixed(2),
                    cooldown: this.timeDiff(this.pot.LastAskDate, parseInt(this.pot.CooldownMinutes)),
                    sellTime: this.sellTimeDiff(this.pot.LastBidDate)
                }
            } else {
                return {}
            } 
        },

        buyAction(){
            const bc = this.pot.BotControlled == 1
            const te = this.pot.TradingEnabled == 1 
            const be = this.pot.BuyEnabled == 1
            if(bc && te && be){
                return this.status == "Sell" ? {txt:'looking for a Sell', details: true} : {txt:'Looking for a Buy', details: true}
            } else if(!bc) {
                return {txt:'Crypton Disabled'}
            } else if(!te){
                return {txt:'Trading Disabled'}
            } else if(!be ){
                return {txt:'Buy Disabled'}
            } else {
                return {txt:'Trading Error'}
            }
        },

        vals(){
            if(this.coinRate){
                const rate = parseFloat(this.coinRate[0].Rate)
                const buy = parseFloat(this.pot.LastBidRate)
                const sell = buy * (1 + (parseInt(this.pot.AskAtPercent) / 100))
                const pct = parseInt(this.pot.AskAtPercent)
                return {buy, sell, rate, pct}
            }
        }
    },

    methods:{
        dtISO(s){
            return DateTime.fromISO(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
        },

        dtSQL(s){
            return DateTime.fromSQL(s)
        },

        timeDiff(t, cd){
            const end = this.dtSQL(t).plus({minutes: cd})
            const d = end.diff(this.now, ['days', 'hours', 'minutes'])
            const past = d.values.minutes > 0
            return {...d.values, past}
        },

        sellTimeDiff(t){
            const start = this.dtSQL(t)
            return start.diff(this.now, ['days', 'hours', 'minutes'])
        }
    },

    created(){
        this.now = DateTime.local()
        let int = setInterval(() => {
            this.now = DateTime.local()
        }, 10000)
        this.int = int
    },

    beforeDestroy(){
        clearInterval(this.int)
    }

}
</script>