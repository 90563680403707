<template>
    <v-card class="overGry px-2">
        <v-row no-gutters align="center">

            <v-col cols="5" md="4">
                <v-row align="center" no-gutters>
                    <v-col cols="2">
                        <v-avatar :size="sizes.avatar" color="white" ><v-img :src="icon" /></v-avatar>
                    </v-col>
                    <v-col cols="10">
                        <p :class="`${sizes.smallText} mb-0`"> {{ pot.PotName}}</p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="3" md="5">
                <potGauge :pot="pot"/>
            </v-col>

            <v-col cols="4" md="3">
                <v-row>
                    <v-spacer />
                    <v-col cols="auto">
                        <PotTimes :pot="pot" />
                    </v-col>
                </v-row>
            </v-col>
            
        </v-row>
    </v-card>
</template>

<script>
// import { DateTime } from 'luxon'
import potGauge from '@/components/widgets/PotGauge' 
import PotTimes from '@/components/widgets/PotTimes' 

export default {
    name: 'SummaryLinePot',

    props: ['pot', 'src'],

    components: {
        potGauge,
        PotTimes
    },

    data: () => ({
        // now: null,
        // int: null
    }),

    computed:{
        icon(){
            return this.$store.getters.symbolIcon(this.pot.SourceCurrency)
        },
       
        sizes(){
        return this.$store.getters.sizes
        }
    },

    methods:{
        // dtSQL(s){
        //     return DateTime.fromSQL(s)
        // },

        // sellTimeDiff(t){
        //     const start = this.dtSQL(t)
        //     return start.diff(this.now, ['days', 'hours', 'minutes'])
        // }
    },

    // created(){
    //     this.now = DateTime.local()
    //     let int = setInterval(() => {
    //         this.now = DateTime.local()
    //     }, 10000)
    //     this.int = int
    // },

    // beforeDestroy(){
    //     clearInterval(this.int)
    // }

}
</script>