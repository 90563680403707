<template>
    <v-card class="gry">
        <p class="text-h4 text-center pt-4">Links for {{ name }}</p>
        <v-card-text>
            <v-row class="mb-6">
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Home Pages:</p>
                    <p v-for="h in links.homepage" :key="h" class="mb-0"><a :href="h" target="_blank">{{ h }}</a></p>
                </v-col>
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Socials:</p>
                    <v-row v-for="s in socials" :key="s.t" no-gutters>
                        <v-col cols="4">
                            <p class="white--text mb-0">{{ s.t }}</p>
                        </v-col>
                        <v-col cols="8">
                            <p v-if="s.l" class="mb-0"><a :href="s.u" target="_blank">{{ s.u }}</a></p>
                            <p v-else class="mb-0">{{ s.u }}</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
            <v-row class="mb-6">
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Blockchain Sites:</p>
                    <p v-for="l in links.blockchain_site" :key="l" class="mb-0"><a :href="l" target="_blank">{{ l }}</a></p>
                </v-col>
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Forums:</p>
                    <p v-for="f in links.official_forum_url" :key="f" class="mb-0"><a :href="f" target="_blank">{{ f }}</a></p>
                </v-col> 
            </v-row>

            <v-row>
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Bitbucket Repos:</p>
                    <p v-for="bb in links.repos_url.bitbucket" :key="bb" class="mb-0"><a :href="bb" target="_blank">{{ bb }}</a></p>
                </v-col>
                <v-col cols="6">
                    <p class="text-h6 white--text mb-0">Git Hub Repos:</p>
                    <p v-for="gh in links.repos_url.github" :key="gh" class="mb-0"><a :href="gh" target="_blank">{{ gh }}</a></p>
                </v-col> 
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:['links', 'name'],

    computed:{
        socials(){
            return [
                {t:'Facebook:', u:this.links.facebook_username},
                {t:'Twitter:', u:this.links.twitter_screen_name},
                {t:'Telegram:', u:this.links.telegram_channel_identifier},
                {t:'Sub Reddit:', u:this.links.subreddit_url, l:true},
                {t:'Bitcoin Talk id:', u:this.links.bitcointalk_thread_identifier}
            ]
        }
    }
}
</script>

<style>

</style>