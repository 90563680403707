import Vue from 'vue'
import Vuex from 'vuex'
import coinbase from '@/plugins/coinbase'
import { DateTime } from 'luxon'
import { db, auth } from '@/fb'

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [coinbase()],

  state: {
    nowFlag:false,
    now: null,
    authMsg: '',
    desktopComponent: 'DBChoose', 
    user: null,
    settings: null,
    settingsDialog: false,
    apis: null,
    pots: null,
    bittrexSummaryFlag1: false,
    bittrexSummaryFlag2: false,
    bittrexSummaryFlag3: false,
    bittrexSummary: null,
    bittrexSummaryLast: null,
    currencies: null,
    rates: [],
    coinPerformanceDuration: 1,
    coinPerformance: null,
    transactionSellSummary: null,
    transactionBuySummary: null,
    
    
    socketStatus:false,
    socketMessage: null,
    
    
    
    
    
    
    sizes: [
      {layout:'DBChoose', text:'', smallText:'text-caption', smallIcon: true, avatar: 15, potBar: 2, coinPerformanceTitle:true }, //Mobile Proxy
      {layout:'DBNormal', text:'', smallText:'text-caption', smallIcon: true, avatar: 18, potBar: 2 },
      {layout:'DBVertical', text:'text-h5', smallText:'', smallIcon: false, avatar: 25, potBar: 5 },
      {layout:'DBWide', text:'', smallText:'text-caption', smallIcon: true, avatar: 18, potBar: 2,  coinPerformanceTitle:true },
    ],
    






    
    coinicons: null,
    bittrexSummarySmall: null,
    bittrexSummarySmallLast: null,

    coinList: null,
    currentCoin: null,
    currentCoinData: null,
    currentCoinHistory: null,
    coinCurrency: null,
    coinRate: null,
    potSetup: null,
    potTransactions: null,
    resetResponse: [],
  },
  
  mutations: {
    SET_NOW_FLAG(state, payload){
      state.nowFlag = payload
    },

    SET_NOW(state, payload){
      state.now = payload
    },

    AUTH_MSG(state, payload){
      state.authMsg = payload
    },

    SET_LAYOUT(state, payload){
      state.desktopComponent = payload
    },

    SET_SETTINGS_DIALOG(state, payload){
      state.settingsDialog = payload
    },

    SET_USER(state, payload){
      state.user = payload
    },

    CLEAR_USER(state){
      state.user = null
    },

    SET_SETTINGS(state, payload){
      state.settings = payload
    },

    SET_APIS(state, payload){
      state.apis = payload
    },
    
    SET_POTS(state, payload){
      state.pots = Object.values(payload)
    },
    
    SET_BITTREX_L1_FLAG(state, payload){
      state.bittrexSummaryFlag1 = payload
    },

    SET_BITTREX_L2_FLAG(state, payload){
      state.bittrexSummaryFlag2 = payload
    },

    SET_BITTREX_L3_FLAG(state, payload){
      state.bittrexSummaryFlag3 = payload
    },
    
    SET_BITTREX_DATA(state, payload){
      state.bittrexSummaryLast = state.bittrexSummary
      state.bittrexSummary = Object.values(payload)
    },

    SET_CURRENCIES(state, payload){
      state.currencies = payload
    },

    SET_RATES(state, payload){
      state.rates = payload
    },

    SET_COIN_PERFORMANCE_DURATION(state, payload){
      state.coinPerformanceDuration = payload
    },

    SET_COIN_PERFORMANCE(state, payload){
      state.coinPerformance = payload
    },

    SET_SELL_TRANSACTION_SUMMARY(state, payload){
      state.transactionSellSummary = payload
    },

    SET_BUY_TRANSACTION_SUMMARY(state, payload){
      state.transactionBuySummary = payload
    },

    SET_SOCKET_STATUS(state, payload){
      state.socketStatus = payload
    },

    SOCKET_MESSAGE(state, payload){
      state.socketMessage = payload 
    },























    // SET_RATES(state, payload){
    //   payload.forEach(r => {
    //     state.rates[r.symbol] = r
    //   })
    // },












    SET_COIN_ICONS(state, payload){
      state.coinicons = payload
    },



    SET_BITTREX_SMALL_DATA(state, payload){
      state.bittrexSummarySmallLast = state.bittrexSummarySmall
      state.bittrexSummarySmall = payload
    },


    SET_COIN_LIST(state, payload){
      state.coinList = payload
    },
    
    SET_CURRENT_COIN(state, payload){
      state.currentCoin = payload
    },
    
    SET_COIN_DATA_FROM_ID(state, payload){
      state.currentCoinData = payload
    },
    
    SET_COIN_HISTORY_FROM_ID(state, payload){
      state.currentCoinHistory = payload
    },

    SET_COIN_CURRENCY(state, payload){
      state.coinCurrency = payload
    },

    SET_COIN_RATE(state, payload){
      state.coinRate = payload
    },

    SET_POT_SETUP(state, payload){
      state.potSetup = payload
    },
    
    SET_POT_TRANSACTIONS(state, payload){
      state.potTransactions = payload
    },

    SET_RESET_RESPONSE(state, payload){
      state.resetResponse.push(payload)
    },

    CLEAR_COIN_DATA(state){
      state.coinList = null
      state.currentCoin = null
      state.currentCoinData = null
      state.currentCoinHistory = null
      state.coinCurrency = null
      state.coinRate = null
      state.potSetup = null
      state.potTransactions = null
    },

    CLEAR_RESET_RESPONSE(state){
      state.resetResponse.length = 0
    }


  },
  
  actions: {
    setNow({commit, state}){
      commit('SET_NOW_FLAG', true) 

      function start() {
        if(state.nowFlag){
          commit('SET_NOW', DateTime.local()) 
          setTimeout(start, 1000);
        }
      }
      start()
    },

    unsubUser({commit}){
      commit('CLEAR_USER')
      commit('SET_USER', false)
    },

    async subUser({commit}){
      const user = auth.currentUser
      const usrTkn = await user.getIdTokenResult(true)
      commit('SET_USER', usrTkn)
      const settings = await db.collection('config').doc('settings').get()
      commit('SET_SETTINGS', settings.data())
      const apis = await db.collection('config').doc('apis').get()
      commit('SET_APIS', apis.data())
      const coinicons = await db.collection('config').doc('coinicons').get()
      commit('SET_COIN_ICONS', coinicons.data())
    },

    loginUsr({ commit, dispatch }, payload){

      auth.signInWithEmailAndPassword(payload.email, payload.password)
          .then(() =>{
            commit('AUTH_MSG', '')
              dispatch('subUser')
          })
          .catch(err =>{
              commit('AUTH_MSG', err.message)
              console.log(err)
          })
    },

    logoutUsr(){
      auth.signOut()
          .then(() =>{
            console.log('Logged Out')
          })
          .catch(err => {
            console.log(err)
          })
    },

    async getAPIData({commit}, payload){
      const url = payload.api
      const res = await fetch(url)
      const data = await res.json()
      commit(payload.mutation, data)
    },

    async getAPIDataV2({commit, state}, payload){
      const headers = {headers: {'APIAuthKey': state.settings.header}}
      const endpoint = state.apis[payload.api]
      const url = payload.params ? `${endpoint}?${payload.params}` : endpoint
      const res = await fetch(url, headers)      
      const data = await res.json()
      commit(payload.mutation, data)
    },

    getDatal1({commit, dispatch, state}){
      commit('SET_BITTREX_L1_FLAG', true)
      function start() {
        if(state.bittrexSummaryFlag1){
          setTimeout(start, state.settings.refreshRates.l1);
        }
      }
      start()
    },

    getDatal2({commit, dispatch, state}){
      commit('SET_BITTREX_L2_FLAG', true)
      function start() {
        if(state.bittrexSummaryFlag2){
          const dt = DateTime.local().toISODate()
          const potSetup = {api:'potSetup', mutation:'SET_POTS' }
          const bittrexSummary = {api:'bittrexSummary', mutation:'SET_BITTREX_DATA' }
          const bittrexSummaryData = {api:'bittrexSummarySmall', mutation:'SET_BITTREX_SMALL_DATA'}
          const potSellTransactions = {api:'potTransactions', mutation:'SET_SELL_TRANSACTION_SUMMARY', params:`${dt}&end=${dt}&type=SELL&limit=20` }
          const potBuyTransactions = {api:'potTransactions', mutation:'SET_BUY_TRANSACTION_SUMMARY', params:`start=${dt}&end=${dt}&type=BUY&limit=20` }
          dispatch('getAPIDataV2', potSetup)
          dispatch('getAPIDataV2', bittrexSummary)
          dispatch('getAPIDataV2', bittrexSummaryData)
          dispatch('getAPIDataV2', potSellTransactions)
          dispatch('getAPIDataV2', potBuyTransactions)
          setTimeout(start, state.settings.refreshRates.l2);
        }
      }
      start()
    },

    getDatal3({commit, dispatch, state}){
      commit('SET_BITTREX_L3_FLAG', true)
      function start() {
        if(state.bittrexSummaryFlag3){
          const bittrexCurrency = {api:'bittrexCurrency', mutation:'SET_CURRENCIES'}
          const bittrexRates = {api:'bittrexRates', mutation:'SET_RATES'}
          const coinPerfomance = {api:'coinPerfomance', mutation:'SET_COIN_PERFORMANCE', params:`days=${state.coinPerformanceDuration}`}
          dispatch('getAPIDataV2', bittrexCurrency)
          dispatch('getAPIDataV2', bittrexRates)
          dispatch('getAPIDataV2', coinPerfomance)
          setTimeout(start, state.settings.refreshRates.l3);
        }
      }
      start()
    },

    updateCoinPerformanceDuration({commit, dispatch, state}, payload){
      commit('SET_COIN_PERFORMANCE', null)
      commit('SET_COIN_PERFORMANCE_DURATION', payload)
      const coinPerfomance = {api:'coinPerfomance', mutation:'SET_COIN_PERFORMANCE', params:`days=${state.coinPerformanceDuration}`}
      dispatch('getAPIDataV2', coinPerfomance)
    },


    socketStatus({commit}, payload){
      commit('SET_SOCKET_STATUS', payload)
    },

    socketMessage({commit}, payload){
      commit('SOCKET_MESSAGE', payload)
    },

    desktopLayout({commit}, payload){
      commit('SET_LAYOUT', payload)
    },

    settingsDialog({commit}, payload){
      commit('SET_SETTINGS_DIALOG', payload)
    },


















    fetchBittrexData({commit}, payload){
      const proxyurl = "https://cors-anywhere.herokuapp.com/"
      const url = 'https://api.bittrex.com/v3/markets/tickers'
      
      async function start() {
          const res = await fetch(proxyurl + url)
          const data = await res.json()
          commit('SET_RATES', data)
          setTimeout(start, 60000);
        }

      start()
    },













    clearCoinData({commit}){
      commit('CLEAR_COIN_DATA')
    },

    setCurrentCoin({commit}, payload){
      commit('SET_CURRENT_COIN', payload)
    },

    writeResetResponse({commit}, payload){
      commit('SET_RESET_RESPONSE', payload)
    },

    clearResetResponse({commit}){
      commit('CLEAR_RESET_RESPONSE')
    },
    
    
  },

  getters: {

    filteredPots: (state) => {
      if(state.pots){
        return state.pots.filter(i => !state.settings.excludedSourceCurrency.includes(i.SourceCurrency))
      }
    },

    symbols: (state, getters) => {
      if(getters.filteredPots){
        const s = getters.filteredPots.map(p => {
          return {symbol:`${p.SourceCurrency} - ${p.DestinationCurrency}`, source:p.SourceCurrency, destination:p.DestinationCurrency}
        })

      return s.filter((thing, index, self) =>
          index === self.findIndex((t) => (
          t.symbol === thing.symbol
        ))
      )
      } else {
        return []
      }
    },

    symbolIcon: (state) => (symbol) => {
      if(state.currencies){
        return state.currencies.find(c => c.symbol == symbol).logoUrl
      }
    },

    bittrexSymbols: (state, getters) => {
      if(state.bittrexSummary && state.currencies){
        let sym = getters.symbols.map(sy => {
          const data = state.bittrexSummary.find(s => s.Currency == sy.source)
          const currencyS = state.currencies.find(c => c.symbol == sy.source)
          return {...sy, data, currencyS}
        })
        return sym
      } else {
        return []
      }
    },

    lastSymbolDelta: (state) => (symbol) => {
      if(state.bittrexSummaryLast){
        return state.bittrexSummaryLast.find(s => s.Currency == symbol).EstimatedDeltaValue
      } else {
        return null
      }
    },

    potsWithSymbol: (state) => (source, destination) => {
      return state.pots.filter(p => p.SourceCurrency == source && p.DestinationCurrency == destination)
    },


    potBreakdown: state => {
      if(state.pots){
        const total = state.pots.filter(p => !state.settings.excludedSourceCurrency.includes(p))
        const active = total.filter(p => p.TradingEnabled > 0)
        const buy = active.filter(p => p.DestinationValue > 0)
        const sell = active.filter(p => p.SourceValue > 0)
        return {total, active, buy, sell}
      } else {
        return {active:[], total:[], buy:[], sell:[]}
      }
    },

    potsWithRates: (state, getters) => (src) => {
      let pwr = []
      getters.potBreakdown[src].forEach(p => {
        const s = `${p.SourceCurrency}-${p.DestinationCurrency}`
        const rate = state.rates.find(r => r.symbol == s)
        const BidDifference = parseFloat(rate.lastTradeRate) - parseFloat(p.LastBidRate)
        const AskDifference = parseFloat(rate.lastTradeRate) - parseFloat(p.LastAskRate)
        const BidDifferencePercent = (BidDifference / parseFloat(rate.lastTradeRate)) * 100
        const AskDifferencePercent = (AskDifference / parseFloat(rate.lastTradeRate)) * 100
        const potState = p.SourceValue > 0 ? 'sell' : 'buy'
        const SortDifference = p.SourceValue > 0 ? BidDifferencePercent : Math.abs(AskDifferencePercent)
        let time = null
        let cooldown = false

        if(potState == 'buy'){
          const end = DateTime.fromSQL(p.LastAskDate).plus({minutes: p.CooldownMinutes})
          time = end.diff(state.now, ['days', 'hours', 'minutes'])
          cooldown = time.minutes > 0 ? true : false
        } 
        if( potState == 'sell'){
          const start = DateTime.fromSQL(p.LastBidDate)
          time = start.diff(state.now, ['days', 'hours', 'minutes'])
        }
  
        pwr.push({...p, ...rate, BidDifference, AskDifference, BidDifferencePercent, AskDifferencePercent, potState, SortDifference, time, cooldown})
      })
      return pwr
    },

    transactionSells: (state, getters) => {
      if(state.transactionSellSummary){
        return state.transactionSellSummary.map(t => {
          const start = DateTime.fromSQL(t.TransactionDate)
          const time = start.diff(state.now, ['hours', 'minutes'])
          const pot = t.PotName
          const src = t.SourceCurrency
          const comission = t.Comission 
          const ico = getters.symbolIcon(src)
          const profit = t.PercentProfit
          const balance = t.BalanceValue

          return {time, pot, src, comission, ico, profit, balance}
        })
      }
    },

    transactionBuys: (state, getters) => {
      if(state.transactionBuySummary){
        return state.transactionBuySummary.map(t => {
          const start = DateTime.fromSQL(t.TransactionDate)
          const time = start.diff(state.now, ['hours', 'minutes'])
          const pot = t.PotName
          const src = t.SourceCurrency
          const comission = t.Comission 
          const ico = getters.symbolIcon(src)
          const profit = t.PercentProfit

          return {time, pot, src, comission, ico, profit}
        })
      }
    },





    BittrexSummarySmallFormatted: state => {
      const b = state.bittrexSummarySmall
      const s = state.settings
      if(b && s){
        return {
            totalUSD: b.TotalUSD.toFixed(2),
            totalInvested: b.TotalInvested.toFixed(2),
            totalStake: b.TotalStake.toFixed(2),
            totalCrypto: b.TotalCrypto.toFixed(2),
            totalCRD: b.TotalCRD.toFixed(2),
            totalValue: b.TotalValue.toFixed(2),
            coinCount: b.CoinCount,
            usdBuyCount: b.BuyCount,
            usdBuyVal: b.BuyValue.toFixed(2),
            usdSellCount: b.SellCount,
            usdSellVal: b.SellValue.toFixed(2),
            usdtBuyCount: b.USDTBuyCount,
            usdtBuyVal: b.USDTBuyValue.toFixed(2),
            usdtSellCount: b.USDTSellCount,
            usdtSellVal: b.USDTSellValue.toFixed(2),
            usdToday: b.BalanceToday.toFixed(2),
            usdTotal: b.BalanceValue.toFixed(2),
            usdtToday: b.USDTBalanceToday.toFixed(2),
            usdtTotal: 0,
            totalToday: (b.BalanceToday + b.USDTBalanceToday).toFixed(2),
            botStakeValue: b.BotStakeValue.toFixed(2),
            botPotAvailable: b.BotPotAvailable.toFixed(2),
            botCooldownValue: b.BotCooldownValue.toFixed(2),
            botAvailableFloat: (b.BotPotAvailable - b.BotCooldownValue).toFixed(2),
            investedPercentage: ((b.TotalInvested / b.TotalStake) *100 ).toFixed(1),
            availableToInvest: (b.TotalUSD - b.BotPotAvailable - s.safeUSD).toFixed(2)
        }
      } else {
        return null
      }
    },


    sizes: state => {
      return state.sizes.find(s => s.layout == state.desktopComponent)
    }
    
  }

})
