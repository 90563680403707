<template>
    <v-card class="grey darken-3 pa-4">
        <p class="text-h6 text-center">Add a new Pot to Coin</p>
        <p class="grey--text">This will create a new pot using the details supplied below.</p>

        <v-row justify="center" >
            <v-col cols="5">
                <v-text-field v-model="pot.SourceCurrency" solo dense label="Source Currency" hint="Use Bittrex Symbol" persistent-hint />
            </v-col>
            <v-col cols="5">
                <v-text-field v-model="pot.DestinationCurrency" solo dense label="Destination Currency" hint="Use Bittrex Symbol" persistent-hint />
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="pot.Stake" solo dense label="Stake" prefix="$" hint="Nominally $20" persistent-hint />
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="pot.NumberOfDowns" solo dense label="Number of Downs" hint="Often 1, 2 or 3" persistent-hint />
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="pot.AskAtPercent" solo dense label="Ask at Percentage" suffix="%" hint="Often 3%, 4% or 5%" persistent-hint />
            </v-col>

            <v-col cols="6">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="pot.CooldownMin" solo dense label="Minimum Cooldown" hint="Nominally 5 minutes" persistent-hint />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="pot.CooldownMax" solo dense label="Maximum Cooldown" hint="Nominally 30 minutes" persistent-hint />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="pot.PersonResponsible" solo dense label="Person Responsible" hint="Who takes the glory" persistent-hint />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="pot.Notes" solo dense label="Notes" hint="Anything of note" persistent-hint />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="pot.DisplayPriority" solo dense label="Display Priority" hint="0 is automatic" persistent-hint />
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="5">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-switch v-model="pot.BotControlled" dense label="Trading Enabled" />
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-model="pot.BuyEnabled" dense label="Buy Enabled" />
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-model="pot.SellEnabled" dense label="Sell Enabled" />
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-model="pot.SlackBuy" dense label="Slack Buy Enabled" />
                    </v-col>
                    <v-col cols="12">
                        <v-switch v-model="pot.SlackSell" dense label="Slack Sell Enabled" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        

        <v-row no-gutters justify="center" class="my-4">
            <v-btn class="grey" @click="onAddNewPot">Confirm Add Pot</v-btn>
        </v-row>

        <v-row justify="center" class="mt-6">
            <p :class="`${col}--text`">{{ response }}</p>
            <p class="caption-text grey--text">{{ subResponse }}</p>
        </v-row>

        <v-row>
            <v-spacer />
            <v-btn small color="gry" @click="onClose">Close</v-btn>
        </v-row>
    </v-card>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'AddNewPot',

    props:['resetAllowed', 'resetParams'],

    data: () => ({
        response: 'Resting...',
        subResponse: '',
        col: 'white',
        pot:{
            SourceCurrency: '',
            DestinationCurrency: '',
            Stake: '',
            NumberOfDowns: '',
            AskAtPercent: '',
            CooldownMin: '',
            CooldownMax: '',
            PersonResponsible: '',
            Notes: '',
            BotControlled: true,
            BuyEnabled: true,
            SellEnabled: true,
            SlackBuy: true,
            SlackSell: true,
            DisplayPriority: 0
        }
    }),

    computed:{
        ...mapState({
            settings: state => state.settings
        }),
    },

    methods:{
        onAddNewPot(){
            const arr = Object.entries(this.pot)
            const json = JSON.stringify(arr)
            console.log(json)
        },

        onClose(){
            this.$emit('close')
        }
    },

    watch:{
        //Watch for response from server and action display here
    }

}
</script>