<template>
  <v-card class="px-6 CnBgGrad">
    <v-list dense color="transparent">
        <v-subheader>Crypton Control Panel</v-subheader>
        <v-list-item-group color="primary">

            <v-list-item @click="onLayout">
                <v-list-item-icon>
                    <v-icon>mdi-view-dashboard-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Layout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click="onSettings">
                <v-list-item-icon>
                    <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
   methods:{
       onLayout(){
           this.$store.dispatch('desktopLayout', 'DBChoose')
       },

       onSettings(){
           this.$store.dispatch('settingsDialog', true)
       }
   }
  }
</script>