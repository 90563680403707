<template>
  <div class="fill-height" >
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="10" md="5" xl="4">
          <p class="text-center pb-4">Choose your Layout</p>
          <v-row>
            <v-col cols="4">
              <v-card color="gry" @click="onLayout('DBVertical')">
                <v-row justify="center" align="center" class="fill-height">
                  <v-col cols="auto">
                    <v-img src="https://firebasestorage.googleapis.com/v0/b/crypto-nite.appspot.com/o/cryptonite_icons%2FCryptoNite_SVG_DBVertical.svg?alt=media&token=8f0d791e-ad8d-4bcc-b749-340cf1d936df" />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card color="gry" @click="onLayout('DBNormal')">
                <v-row justify="center" align="center" class="fill-height">
                  <v-col cols="auto">
                    <v-img src="https://firebasestorage.googleapis.com/v0/b/crypto-nite.appspot.com/o/cryptonite_icons%2FCryptoNite_SVG_DBNormal.svg?alt=media&token=418f068b-7e6f-4f9d-8e78-d2b87869ac8b" />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card color="gry" @click="onLayout('DBWide')">
                <v-row justify="center" align="center" class="fill-height">
                  <v-col cols="auto">
                    <v-img src="https://firebasestorage.googleapis.com/v0/b/crypto-nite.appspot.com/o/cryptonite_icons%2FCryptoNite_SVG_DBWide.svg?alt=media&token=ea0800d3-cf82-4dc0-91ab-44bac3ef6b7e" />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        
        
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  methods:{
    onLayout(l){
      this.$store.dispatch('desktopLayout', l)
    }
  }
}
</script>

<style>

</style>