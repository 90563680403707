<template>
    <v-card color="gry" class="mx-4 pa-4 mb-4">
        <p class="text-h6 white--text">Pots</p>
        <v-row v-for="p in pots" :key="p.Record">
            <v-col cols="11">
                <v-card class="overGry">
                    <v-row no-gutters>
                        <v-col cols="3">
                            <p class="text-caption mb-0"><v-icon left class="mb-1 ml-2" :color="status(p).c">mdi-cash-multiple</v-icon> {{ p.PotName }}</p>
                        </v-col>
                        <v-col cols="9" class="">
                            <PotSummary :pot="p" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="1">
                <v-btn small icon @click="onPotDetails(p)"><v-icon color="primary">mdi-apps-box</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-card>

</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import PotSummary from '@/components/parts/PotSummary'

export default {
    name: 'CoinPotPerformance',

    props:['potDet'],

    components: {
        PotSummary
    },

    data: () => ({
      
    }),

    computed:{
        ...mapState({
            potSetup: state => state.potSetup,
        }),

        pots(){
            if(this.potSetup){
                return Object.values(this.potSetup)
            } else {
                return []
            }
        }


       
        
    },

    methods:{
        dtISO(s){
            const dt = DateTime.fromISO(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
            return dt
        },

        status(p){
            const te = p.TradingEnabled == 1 
            const be = p.BuyEnabled == 1
            const se = p.SellEnabled == 1
            const bc = p.BotControlled == 1
            const l = p.SourceValue > 0 ? 'Sell' : 'Buy'
            let c = 'red'
            if(bc && te && be && se){
                c = l == "Sell" ? 'sell' : 'buy'
            } else {
                c = 'orange'
            }
            return {l,c}
        },

        onPotDetails(p){
            this.$emit('potDetail', p)
        }
    },

}
</script>