<template>
    <component :is="component" />
</template>

<script>
import { mapState } from 'vuex'
import DBDesktop from '@/components/DashBoard/Desktop'
import DBMobile from '@/components/DashBoard/Mobile'

export default {
    name: 'Dashboard',

    components: {
        DBDesktop,
        DBMobile
    },

    data: () => ({
        apis:[
            {api:'bittrexSummary', mutation:'SET_BITTREX_DATA', intervalTime: 60000, params:null, interval:null, initial:true},
            {api:'bittrexSummarySmall', mutation:'SET_BITTREX_SMALL_DATA', intervalTime: 60000, params:null, interval:null, initial:true}
        ],
    }),

    computed:{
        ...mapState({
            tableSort: state => state.tableSort
        }),

        component(){
            return this.$vuetify.breakpoint.mobile ? 'DBMobile' : 'DBDesktop'
        },
    },

    methods:{
        onInitialFetchAPI(){
            //Initial Fetch of endpoints
            this.apis.forEach(a => {
              if(a.initial){
                  this.$store.dispatch('getAPIDataV2', a)
              }  
            })
        },

        onFetchAPIInterval(){
            //Initial creating of intervals
            this.apis.forEach((a,i) => {
                if(a.intervalTime > 0){
                    a.interval = setInterval(() => {
                        this.$store.dispatch('getAPIDataV2', a)
                    }, a.intervalTime)
                }
            })
        },

        onClearAPIIntervals(){
            this.apis.forEach(a => {
                clearInterval(a.interval)
            })

        }
    },

    created(){        
        this.onInitialFetchAPI()
        this.onFetchAPIInterval()
    }
}
</script>
