<template>
    <v-row no-gutters>
        <v-col cols="12">
            <p :class="`${sizes.text} font-weight-bold green--text mb-1`">Steve's Status</p>
        </v-col>

        <v-col v-if="steveStatus" cols="12">
             <v-stepper v-model="steveStatus" class="transparent elevation-0" vertical >
                <v-stepper-step v-for="(s,i) in steveStatus" :key="i" :complete="profit > s.limit" :step="s.order">
                    <p :class="`${sizes.text} mb-0`">{{ s.text }}</p>
                </v-stepper-step>
               
            </v-stepper>
        </v-col>

        <v-col v-else>
            <p class="text- caption">Getting Data</p>
            <v-progress-linear indeterminate height="1" class="mx-4" color="primary" />
        </v-col>

    </v-row>
</template>

<script>

export default {
    name: 'SummarySteveStatus',

    components: {

    },

    data: () => ({

    }),

    computed:{
        sizes(){
            return this.$store.getters.sizes
        },

        steveStatus(){
            const set = this.$store.state.settings
            if(set){
                return set.steveStatus
            }
        },

        profit(){
            const bss = this.$store.getters.BittrexSummarySmallFormatted
            if(bss){
                return bss.totalToday
            }
        }

    }        

}
</script>