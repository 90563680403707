<template>
        <v-row no-gutters align="center" >
            <v-col cols="2" md="3">
                <v-slider dense hide-details :thumb-color="tail.thumb" :color="tail.color" :track-color="tail.track" v-model="tail.value" />
            </v-col>
            <v-col cols="2" md="3">
                    <v-slider dense hide-details :thumb-color="box.thumb" :color="box.color" :track-color="box.track"  v-model="box.value"/>
            </v-col>
            <v-col cols="2" md="3">
                <v-slider dense hide-details :thumb-color="head.thumb" :color="head.color" :track-color="head.track" v-model="head.value" />
            </v-col>
            <v-col cols="6" md="3">
                <p :class="`text-right ${sizes.smallText} mb-0`">{{ currentPercent }}%</p>
            </v-col>
        </v-row>
</template>

<script>
export default {
    name:'PotSummaryGauge',

    props:['pot'],

    data: () => ({
        defaultValues: {thumb:'transparent', color:'grey', track:'grey', value:0}
    }),

    computed:{

        currentPercent(){
            if(this.pot){
                return this.pot.potState == "sell" ? this.pot.BidDifferencePercent.toFixed(2) : this.pot.AskDifferencePercent.toFixed(2)
            }
        },

        segment(){
            if(this.currentPercent && this.pot.potState == "sell"){
                return this.currentPercent > 0 ? this.currentPercent < this.pot.AskAtPercent ? 'box' : 'head' : 'tail'
            } else if(this.currentPercent && this.pot.potState == "buy"){
                return this.currentPercent < 0 ? parseFloat(this.currentPercent) > -10 ? 'box' : 'tail' : 'head'
            }
        },

        tail(){
            if(this.segment && this.pot.potState == "sell" && this.segment == 'tail'){
                const thumb = 'red'
                const color = 'grey'
                const track = 'red'
                const value = 100 + parseFloat(this.currentPercent)
                return {thumb, color, track, value}
            } else if(this.segment && this.pot.potState == "buy" && this.segment == 'tail'){
                const thumb = 'green'
                const color = 'grey'
                const track = 'green'
                const value = 100 + parseFloat(this.currentPercent)
                return {thumb, color, track, value}
            } else {
                return this.defaultValues
            }
        },

        box(){
            if(this.segment && this.pot.potState == "sell" && this.segment == 'box'){
                const thumb = 'green'
                const color = 'green'
                const track = 'grey'
                const value = (parseFloat(this.currentPercent) / this.pot.AskAtPercent) * 100
                return {thumb, color, track, value}
            } else if(this.segment && this.pot.potState == "buy" && this.segment == 'box'){
                const thumb = 'orange'
                const color = 'grey'
                const track = 'orange'
                const value = 100 + (parseFloat(this.currentPercent) * 10)
                return {thumb, color, track, value}
            } else if(this.segment && this.segment == 'tail'){
                    const thumb = 'transparent'
                    const color = 'green'
                    const track = 'green'
                    const value = 0
                    return this.pot.potState == 'sell' ? this.defaultValues : {thumb, color, track, value}
            } else if(this.segment && this.segment == 'head'){
                    const thumb = 'transparent'
                    const color = 'sell'
                    const track = 'sell'
                    const value = 0
                    return this.pot.potState == 'buy' ? this.defaultValues : {thumb, color, track, value}
            } else {
                return this.defaultValues
            }
        },

        head(){
            if(this.segment && this.pot.potState == "sell" && this.segment == 'head'){
                const thumb = 'sell'
                const color = 'sell'
                const track = 'grey'
                const value = parseFloat(this.currentPercent) * 10
                return {thumb, color, track, value}
            } else if(this.segment && this.pot.potState == "buy" && this.segment == 'head'){
                const thumb = 'red'
                const color = 'red'
                const track = 'grey'
                const value = parseFloat(this.currentPercent) * 10
                return {thumb, color, track, value}
            } else {
                return this.defaultValues
            }
        },

        sizes(){
            return this.$store.getters.sizes
        }

    }
}
</script>