


const url = 'wss://ws-feed.pro.coinbase.com'

const subMsg = {
    "type": "subscribe",
    "product_ids": [
        "ADA-USD",
        "ETH-EUR"
    ],
    "channels": [
        "level2",
        "heartbeat",
        {
            "name": "ticker",
            "product_ids": [
                "ETH-EUR",
                "ADA-USD"
            ]
        }
    ]
}

let socket = new WebSocket(url)





export default function createCoinbaseWebSocketPlugin() {
    return store => {
        socket.onopen = (e) => {
            store.dispatch('socketStatus', true)
            console.log('socket opened')
            socket.send(JSON.stringify(subMsg))
        }
        
        socket.onmessage = (m) => {
            store.dispatch('socketMessage', JSON.parse(m.data))
        }

        socket.onerror = (e) => {
            store.dispatch('socketStatus', false)
            console.log(e)
        }

        socket.onclose = (e) => {
            store.dispatch('socketStatus', false)
            console.log(e)
        }

//         client.on('stateChanged', (oldState, newState) => {
//         if (oldState !== newState && newState !== 'Connected')
//             store.dispatch('connectionClosed');
//         else store.dispatch('connectionOpened');
//         });
// start()
        // client.start()
        // .then(() => {
        //     store.dispatch('connectionOpened');
        // })
        // .catch(err => {
        //     store.dispatch('connectionError', err);
        // });


    };
}








