import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/functions'
import '@firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyBrjZ4JvRnYyChkU5T2Abww9afQH4Vs1dc",
  authDomain: "crypto-nite.firebaseapp.com",
  projectId: "crypto-nite",
  storageBucket: "crypto-nite.appspot.com",
  messagingSenderId: "209335600046",
  appId: "1:209335600046:web:1dc0166169c21480eb7e3f",
  measurementId: "G-DWZKCGQ4Z5"
};


  firebase.initializeApp(firebaseConfig)
  export const firestore = firebase.firestore
  export const db = firebase.firestore()
  export const auth = firebase.auth()
  export const functions = firebase.functions()
  export const storage = firebase.storage()

