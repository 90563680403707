<template>
    <div>
        <v-card color="gry" class="pa-4">
            <p class="text-h6 white--text">Overview</p>
            <v-row class="mt-4">
                <v-col cols="6">
                    <v-row v-for="(p,i) in cD" :key="i" no-gutters>
                        <v-sheet v-if="p.s" height="25"/>
                        <v-col cols="6">
                            <p class="mb-0 grey--text text--darken-1">{{ p.k }}</p>
                        </v-col>
                        <v-col cols="6">
                            <p class="text-right mb-0"><span v-if="p.d">$ </span>{{ p.v }}<span v-if="p.pc"> %</span></p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row v-for="p in cR" :key="p.k" no-gutters>
                        <v-sheet v-if="p.s" height="25"/>
                        <v-col cols="6">
                            <p class="mb-0 grey--text text--darken-1">{{ p.k }}</p>
                        </v-col>
                        <v-col cols="6">
                            <p :class="`${p.c ? p.c : 'white'}--text text-right mb-0`"><span v-if="p.d">$ </span>{{ p.v }}</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: 'CoinPotSummary',

    components: {
   
    },

    data: () => ({
      
    }),

    computed:{
        ...mapState({
            currentCoin: state => state.currentCoin,
            coinCurrency: state => state.coinCurrency,
            coinRate: state => state.coinRate,
        }),

        cD(){
            if(this.coinCurrency){
                const r =this.coinCurrency[0]
                return [
                    {k:'Source:', v:r.SourceCurrency},
                    {k:'Value:', v:r.SourceValue},
                    {k:'Destination:', v:r.DestinationCurrency},
                    {k:'Value:', v:r.DestinationValue},
                    {s:true},
                    {k:'Pot Total:', v:r.PotCount},
                    {k:'Total Stake:', v:parseInt(r.StakeValue).toFixed(2), d:true},
                ]
            } else {
                return []
            }
        },

        cR(){
            if(this.coinRate && this.coinCurrency){
                const k = this.coinRate[0]
                const r = this.coinCurrency[0]
                const i = parseFloat(r.StakeValue - r.DestinationValue)
                const d = parseFloat(k.Rate * r.SourceValue)
                const dCol = (d-i) >=0 ? 'green' : 'red'
                return [
                    {k:'Ask Rate:', v:k.Rate},
                    {k:'On:', v:this.dtSQL(k.RateTime)},
                    {s:true},
                    {k:'Invested:', v:i.toFixed(2), d:true},
                    {k:'~Destination:', v:d.toFixed(2), d:true},
                    {k:'~Delta:', v:(d-i).toFixed(2), d:true, c:dCol},

                ]
            } else {
                return []
            }
        },
        
    },

    methods:{
        dtSQL(s){
            const dt = DateTime.fromSQL(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
            return dt
        },

        onPotDetails(p){
            this.$emit('potDetails', p)
        }
    },

}
</script>