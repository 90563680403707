<template>
    <v-card class="overGry px-2">
        <v-row no-gutters align="center">
            <v-col cols="4" md="3">
                <v-row align="center" no-gutters>
                    <v-col cols="3">
                        <v-avatar :size="sizes.avatar" color="white"><v-img :src="symbol.currencyS.logoUrl" /></v-avatar>
                    </v-col>
                    <v-col cols="9">
                        <p :class="`${sizes.smallText} mb-0`"> {{symbol.source}} - {{symbol.destination}}</p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="1">
                <v-tooltip bottom color="grey darken-2">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-card tile flat class="transparent py-1">
                                <v-progress-linear rounded  background-color="buy" color="sell" :height="sizes.potBar" :value="symbol.data.TotalInvestedPercent" />
                            </v-card>
                        </span>
                    </template>
                    <span>{{ potString }}</span>
                </v-tooltip>
            </v-col>

            <v-col cols="5">
                <v-row no-gutters>
                    <v-col cols="3">
                        <p :class="`${sizes.smallText} text-right mb-0 pr-3`">{{ pots.length }}</p>
                    </v-col>
                    <v-col cols="7">
                        <v-card :color="deltaColour">
                            <p :class="`${sizes.smallText} text-center mb-0`">{{ delta }}</p>
                        </v-card>
                    </v-col>
                    <v-col cols="2" >
                        <v-icon :small="sizes.smallIcon" :color="deltaChange.c" class="pl-1">{{ deltaChange.i }}</v-icon>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="2" md="3">
                <v-row no-gutters>
                    <v-spacer />
                        <v-col cols="auto">
                            <v-tooltip v-for="(e,j) in potErrors" :key="j"  bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon :color="e.col" :small="sizes.smallIcon" v-bind="attrs" v-on="on">
                                        {{ e.icon }}
                                    </v-icon>
                                </template>
                                <span>{{ e.str }}</span>
                            </v-tooltip>
                        </v-col>
                </v-row>
            </v-col>
            
        </v-row>
    </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    name: 'SummaryLineCoin',

    props: ['symbol', 'size'],

    components: {
        
    },

    data: () => ({
        now: null,
        int: null
    }),

    computed:{

        pots(){
            return this.$store.getters.potsWithSymbol(this.symbol.source, this.symbol.destination)
        },

        buyingPots(){
            return this.pots.filter(p => p.DestinationValue > 0)
        },

        sellingPots(){
            return this.pots.filter(p => p.SourceValue > 0)
        },

        potString(){
            if(this.buyingPots && this.sellingPots){
                return `${this.buyingPots.length} Buying
                ${this.sellingPots.length} Selling`
            }
        },

        potErrors(){
            let err = []
            const bot = this.pots.filter(p => p.BotControlled == 0).length
            const trade = this.pots.filter(p => p.TradingEnabled == 0).length
            const buy = this.pots.filter(p => p.BuyEnabled == 0).length
            const sell = this.pots.filter(p => p.SellEnabled == 0).length

            bot > 0 ? err.push({icon:'mdi-robot-off-outline', col:'error', str:`${bot} Pot${bot > 1 ? 's' : ''} not controlled by Crypton`}) : null
            trade > 0 ? err.push({icon:'mdi-robot-confused-outline', col:'warning', str:`Trading not enabled on ${trade} Pot${trade > 1 ? 's' : ''}`}) : null
            buy > 0 ? err.push({icon:'mdi-robot-off-outline', col:'buy', str:`Buying not enabled on ${buy} Pot${buy > 1 ? 's' : ''}`}) : null
            sell > 0 ? err.push({icon:'mdi-robot-off-outline', col:'sell', str:`Selling not enabled on ${sell} Pot${sell > 1 ? 's' : ''}`}) : null
            this.discrepancy.abs > 0.1 && this.discrepancy.abs != 100 ? err.push({icon:'mdi-alert-outline', col:'warning', str:`Discrepancy of ${this.discrepancy.diff}%`}) : null

            return err
        },

        errorPots(){
            const bot = this.pots.filter(p => p.BotControlled == 0).length
            const trade = this.pots.filter(p => p.TradingEnabled == 0).length
            const buy = this.pots.filter(p => p.BuyEnabled == 0).length
            const sell = this.pots.filter(p => p.SellEnabled == 0).length
            return {bot, trade, buy, sell}
        },

        oldPot(){
            if(this.sellingPots.length > 0){
                let sellDates = this.sellingPots.map(p => p.LastBidDate)
                sellDates.sort((a, b) => new Date(a.date) - new Date(b.date))
                const dt = this.sellTimeDiff(sellDates[0]).values
                return dt.days < -5 ? true : false
            } else {
                return false
            }
        },

        discrepancy(){
            const diff = this.symbol.data.SourceValue > 0 ? ((this.symbol.data.Discrepancy / this.symbol.data.SourceValue)*100).toFixed(2) : 0
            const abs = Math.abs(diff)
            return {diff, abs}
        },

        delta(){
            return this.sellingPots.length > 0 ? this.symbol.data.EstimatedDeltaValue.toFixed(2) : ' . '
        },

        deltaColour(){
            return this.sellingPots.length > 0 ? this.delta > 0 ? 'green darken-2' : 'red darken-2' : 'grey darken-2'
        },

        deltaChange(){
            const lastValue = this.$store.getters.lastSymbolDelta(this.symbol.source)
                if(lastValue){
                    const diff = this.symbol.data.EstimatedDeltaValue / lastValue
                    if(diff == 1){
                        return {c:'grey darken-2', i:'mdi-arrow-right'}
                    } else if(diff > 1){
                        return {c:'green lighten-2', i:'mdi-arrow-up'}
                    } else {
                        return {c:'red lighten-2', i:'mdi-arrow-down'}
                    }
                } else {
                    return {c:'grey', i:''}
                }
        },

        sizes(){
        return this.$store.getters.sizes
        }
    },

    methods:{
        dtSQL(s){
            return DateTime.fromSQL(s)
        },

        sellTimeDiff(t){
            const start = this.dtSQL(t)
            return start.diff(this.now, ['days', 'hours', 'minutes'])
        }
    },

    created(){
        this.now = DateTime.local()
        let int = setInterval(() => {
            this.now = DateTime.local()
        }, 10000)
        this.int = int
    },

    beforeDestroy(){
        clearInterval(this.int)
    }

}
</script>