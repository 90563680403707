<template>

    <v-card color="gry" class="mx-4 pa-4">
        <v-row>
            <v-col cols="auto">
                <p class="text-h6 text-center white--text ">Pot Details</p>
            </v-col>
            <v-spacer />
            <v-col v-if="p" cols="auto">
                <v-btn small color="grey" class="mt-1 mx-2" >Edit Pot <span class="ml-2 text-caption grey--text text--darken-2">{{ p.Record }}</span></v-btn>
            </v-col>
        </v-row>
        
        <v-row no-gutters class="mt-4">
            <v-col cols="6">
                <v-row v-for="p in pL" :key="p.k" no-gutters>
                    <v-col cols="6">
                        <p class="mb-0 grey--text text--darken-1">{{ p.k }}</p>
                    </v-col>
                    <v-col cols="6">
                        <p class="mb-0"><span v-if="p.d">$ </span>{{ p.v }}<span v-if="p.pc"> %</span></p>
                    </v-col>
                </v-row>

            </v-col>
            <v-col cols="6">
                <v-row v-for="p in pC" :key="p.k" no-gutters>
                    <v-col cols="6">
                        <p class="mb-0 grey--text text--darken-1">{{ p.k }}</p>
                    </v-col>
                    <v-col cols="6">
                        <p class="mb-0"><span v-if="p.d">$ </span>{{ p.v }}</p>
                    </v-col>
                </v-row>
                <v-sheet class="transparent" height="20" />
                <v-row v-for="p in pR" :key="p.k" no-gutters>
                    <v-col cols="6">
                        <p class="mb-0 grey--text text--darken-1">{{ p.k }}</p>
                    </v-col>
                    <v-col cols="6">
                        <p class="mb-0"><span v-if="p.d">$ </span>{{ p.v }}</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col cols="6">
                <p v-if="p" class="mb-0"><span class="grey--text text--darken-1">Person Responsible: </span> {{ p.PersonResponsible }}</p>
            </v-col>
            <v-col cols="6">
                <span :class="`${p.v}--text text-caption mr-2`" v-for="p in pE" :key="p.k">~{{ p.k }}</span>
            </v-col>
            <v-col cols="12">
                <p v-if="p" class="mb-0"><span class="grey--text text--darken-1">Notes: </span> {{ p.Notes }}</p>                    
            </v-col>
        </v-row>
    </v-card>

</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: 'PotDetails',

    props:['p'],

    components: {
   
    },

    data: () => ({
      
    }),

    computed:{
        ...mapState({

        }),

        pL(){
            if(this.p){
                return [
                    {k:'Name:', v:this.p.PotName},
                    {k:'Source Currency:', v:this.p.SourceCurrency},
                    {k:'Destination Currency:', v:this.p.DestinationCurrency},
                    {k:'Stake Value:', v:parseInt(this.p.StakeValue).toFixed(2), d:true},
                    {k:'Bid At Percentage:', v:this.p.BidAtPercent, pc:true},
                    {k:'Ask At Percentage:', v:this.p.AskAtPercent, pc:true},
                    {k:'Number of Downs:', v:this.p.NumberOfDowns, },
                    {k:'Cooldown Min:', v:this.p.CooldownMin, },
                    {k:'Cooldown Max:', v:this.p.CooldownMax, },
                ]
            } else {
                return []
            }
        },

        pC(){
            if(this.p){
                return [
                    {k:'Source Value:', v:this.p.SourceValue},
                    {k:'Destination Value:', v:this.p.DestinationValue},
                    {k:'Cooldown Minutes:', v:this.p.CooldownMinutes},
                ]
            } else {
                return []
            }
        },

        pR(){
            if(this.p){
                return [
                    {k:'Last Bid Rate:', v:this.p.LastBidRate},
                    {k:'Last Bid Date:', v:this.dtSQL(this.p.LastBidDate)},
                    {k:'Last Ask:', v:this.p.LastAskRate},
                    {k:'Last Ask Date:', v:this.dtSQL(this.p.LastAskDate)},  
                    {k:'Display Priority:', v:this.p.DisplayPriority},  
                ]
            } else {
                return []
            }
        },

        pE(){
            if(this.p){
                return [
                    {k:'Trading', v:this.pECol(this.p.TradingEnabled)},
                    {k:'Crypton', v:this.pECol(this.p.BotControlled)},
                    {k:'Sell', v:this.pECol(this.p.SellEnabled)},
                    {k:'Buy', v:this.pECol(this.p.BuyEnabled)},
                    {k:'Slack Buy', v:this.pECol(this.p.SlackBuy)},
                    {k:'Slack Sell', v:this.pECol(this.p.SlackSell)},
                ]
            } else {
                return []
            }
        }


       
        
    },

    methods:{
        dtSQL(s){
            const dt = DateTime.fromSQL(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
            return dt
        },

        pECol(v){
            return v == true ? 'green' : 'red'
        }
    },

}
</script>