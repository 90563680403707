<template>
    <div>
        <v-row v-if="bss">
            <v-col cols="12">
                    <v-row no-gutters>
                        <v-col>
                            <p class="text-center grey--text">Estimated Holdings</p>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="6">
                            <p class=" mb-1">Total USD:</p>
                        </v-col>
                        <v-col cols="6">
                            <p class="text-right  mb-1">$ {{ bss.totalUSD }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="">
                        <v-col cols="6">
                            <p class=" mb-1">Total Crypto:</p>
                        </v-col>
                        <v-col cols="6">
                            <p class="text-right  mb-1">$ {{ bss.totalCrypto}}</p>
                        </v-col>
                    </v-row>
                    <v-divider />

                    <v-row no-gutters class="my-2">
                        <v-col cols="7">
                            <p class=" mb-0">Total Value:</p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0">$ {{ bss.totalValue }}</p>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="my-2">
                        <v-col cols="7">
                            <p class=" mb-0">Available to Invest:</p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0">$ {{ bss.availableToInvest }}</p>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mb-2">
                        <v-col cols="7">
                            <p class=" mb-0">Total CRD:</p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0"> {{ bss.totalCRD }}</p>
                        </v-col>
                    </v-row>

                    <v-row no-gutters >
                        <v-col cols="7">
                            <p class=" mb-0">Total Stake:</p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0">$ {{ bss.totalStake }}</p>
                        </v-col>
                    </v-row>

                        <v-divider />

                    <v-row no-gutters class="mt-2">
                        <v-col cols="7">
                            <p class=" mb-0">Invested</p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0">$ {{ bss.totalInvested }}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-2">
                        <v-col cols="7">
                            <p class=" mb-0"></p>
                        </v-col>
                        <v-col cols="5">
                            <p class=" text-right mb-0">{{ bss.investedPercentage }} %</p>
                        </v-col>
                    </v-row>
            </v-col>
        </v-row>


         <v-row v-else>
            <v-col>
                <p class="">Getting Holding Data</p>
                <v-progress-linear indeterminate height="1" class="mx-4" color="primary" />
            </v-col>
         </v-row>

    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BittrexSummarySmall',

    computed:{
        ...mapState({
            settings: state => state.settings,
        }),

        bss(){
            return this.$store.getters.BittrexSummarySmallFormatted
        }
    }
}
</script>