<template>
    <div>
      <v-tabs v-model="tab" background-color="gry" color="primary" class="mb-2 mx-1">
        <v-tab>Data</v-tab>
        <v-tab>Coins</v-tab>
        <v-tab>Pots</v-tab>
        <v-tab>Rank</v-tab>
        <v-tab>Sells</v-tab>
        <v-tab>Buys</v-tab>
        <v-tab>Stats</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent px-1">
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryTable />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryBittrex />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryCoinPerformance />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryPotPerformance />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryCoinRanking />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summarySells />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryBuys />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summarySteve />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryBuyToSell />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import summaryCoinPerformance from '@/components/V2/DashBoard/Widgets/summaryCoinPerformance'
import summaryPotPerformance from '@/components/V2/DashBoard/Widgets/summaryPotPerformance'
import summaryTable from '@/components/V2/DashBoard/Widgets/summaryTable'
import summaryBittrex from '@/components/V2/DashBoard/Widgets/summaryBittrex'
import summarySteve from '@/components/V2/DashBoard/Widgets/summarySteve'
import summaryBuyToSell from '@/components/V2/DashBoard/Widgets/summaryBuyToSell'
import summaryCoinRanking from '@/components/V2/DashBoard/Widgets/summaryCoinRanking'
import summarySells from '@/components/V2/DashBoard/Widgets/summarySells'
import summaryBuys from '@/components/V2/DashBoard/Widgets/summaryBuys'


export default {
  name: 'DesktopDashboardV2Vertical',

  components: {
    summaryCoinPerformance,
    summaryPotPerformance,
    summaryTable,
    summaryBittrex,
    summarySteve,
    summaryBuyToSell,
    summaryCoinRanking,
    summarySells,
    summaryBuys
  },

  data: () => ({
    sortBy: 'EstimatedDeltaValue',
    tab: null,
  }),

  computed:{
    ...mapState({
        pots: state => state.pots,
        summaries: state => state.bittrexSummary,
        currencies: state => state.currencies,
        rates: state => state.rates,
        message: state => state.socketMessage,

    }),

    symbols(){
      return this.$store.getters.bittrexSymbols  
    },

    sortedSymbols(){
      if(this.symbols){
        return this.symbols.sort((a,b) => a.data[this.sortBy]-b.data[this.sortBy]).reverse()
      } else {
        return []
      }
    },


    potCount(){
      return this.$store.getters.potCount
    },

    limitPots(){
      if(this.pots){
        return this.pots.filter(p => p.SourceCurrency == 'BCH')
      }
    },

    sizes(){
      return this.$store.getters.sizes
    }

  },

  methods:{

  },

  created(){        
    
  }
}
</script>
