<template>
  <div>
    <v-card v-if="sizes.coinPerformanceTitle" color="transparent" flat tile class="">
      <p :class="`${sizes.text} font-weight-bold green--text mb-0`">Pot Performance</p>
    </v-card>
    <v-card color="transparent" flat tile class="pb-2">
      <v-row no-gutters>
          <v-col v-for="b in buttons" :key="b.txt" cols="4" md="2">
              <v-btn :small="sizes.smallIcon" :class="potSource == b.src ? 'overGry' : 'underGry'" @click="potSource = b.src">{{ b.txt }}</v-btn>
          </v-col>
      </v-row>
    </v-card>
    <v-card color="transparent" flat class="OverflowCardY pr-1" :height="height">
      <v-row v-for="p in sortedPots" :key="p.name" no-gutters class="mb-1">
        <v-col cols="12">
          <summaryPotLine :pot="p" :src="potSource" />
          <!-- {{p}} -->
        </v-col>
      </v-row>
    </v-card>
    </div>
</template>

<script>
import summaryPotLine from '@/components/V2/DashBoard/Widgets/summaryPotLine'

export default {
  name: 'SummaryPotPerformance',

  components: {
    summaryPotLine,
  },

  data: () => ({
    sortBy: 'EstimatedDeltaValue',
    potSource: 'sell',
    buttons:[
        {txt:'Selling', src:'sell'},
        {txt:'Buying', src:'buy'},
        {txt:'Active', src:'active'}
    ]
  }),

  computed:{
    pots(){
      return this.$store.getters.potsWithRates(this.potSource)  
    },

    sortedPots(){
      if(this.pots && this.potSource == 'sell'){
        return this.pots.sort((a,b) => a.BidDifferencePercent - b.BidDifferencePercent).reverse()
      } else if(this.pots && this.potSource == 'buy'){
        return this.pots.sort((a,b) => a.AskDifferencePercent - b.AskDifferencePercent)
      } else if(this.pots && this.potSource == 'active'){
        return this.pots.sort((a,b) => a.SortDifference - b.SortDifference).reverse()
      } else {
        return []
      }
    },

    sizes(){
      return this.$store.getters.sizes
    },

    height(){
      return this.$vuetify.breakpoint.smAndDown ? null : this.$vuetify.breakpoint.height - 120
    }
  }
}
</script>

