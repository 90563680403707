import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import {auth} from '@/fb'

import Cryptoicon from 'vue-cryptoicon';
import icon from 'vue-cryptoicon/src/icons';
Cryptoicon.add(icon);
Vue.use(Cryptoicon);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created(){
    auth.onAuthStateChanged((user) => {
      if(user){
        this.$store.dispatch('subUser')
      } else {
        this.$store.dispatch('unsubUser')
      } 
    })
  },
  render: h => h(App)
}).$mount('#app')
