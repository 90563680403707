<template>
  <v-app id="CnBgGrad" dark>

    <v-app-bar app flat class="CnBgGrad">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon small class="ml-1" v-bind="attrs" v-on="on" color="transparent"><v-img src="https://firebasestorage.googleapis.com/v0/b/crypto-nite.appspot.com/o/bitcoin-transfer.svg?alt=media&token=07f80b86-5b00-46a9-8406-569123a7bd85" width="30" /></v-btn>
        </template>
        <AppMenu />
      </v-menu>

      <p class="grey--text text-h6 mt-3 ml-3">Crypto-Nite</p>
      <v-scroll-x-transition> <v-icon small right v-if="heartbeat" :color="heartcolour">mdi-heart-pulse</v-icon> </v-scroll-x-transition>
      <v-spacer />
      <v-app-bar-title class="grey--text text--darken-2 text-caption">{{ usrMail }}</v-app-bar-title>
      <v-btn small icon  @click="onLogout"><v-icon x-small color="grey darken-2">mdi-logout</v-icon></v-btn>
    </v-app-bar>


    <v-main >
      <router-view></router-view>
    </v-main>

    <v-dialog v-model="settingsDialog" persistent width="600">
        <Settings  />
    </v-dialog>

  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import AppMenu from '@/components/widgets/AppMenu'
import Settings from '@/components/widgets/Settings'

export default {
  name: 'App',

  components: {
    AppMenu,
    Settings
  },

  data: () => ({

  }),

  computed:{
    ...mapState({
      user: state => state.user,
      settingsDialog: state => state.settingsDialog,
      heartbeat: state => state.heartbeat,
      heartcolour: state => state.heartcolour
    }),

    usrMail(){
      return this.user ? this.user.claims.email.split('@')[0] : ''
    },

  },


  methods:{
    onLogout(){
      this.$store.dispatch('logoutUsr')
      this.$router.push('/').catch(e => console.log(e))
    },

    onDashboard(){
      this.$router.push('/dashboard').catch(e => console.log(e))
      this.$store.dispatch('clearCurrentCoin')
    }
  }
};
</script>


<style>
#CnBgGrad{
  overflow-x: hidden;
  color: rgba(244,244,245,.6);
  background: radial-gradient(farthest-side ellipse at 10% 0,#333867,#17193b);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.CnBgGrad{
  overflow-x: hidden;
  color: rgba(244,244,245,.6);
  background: radial-gradient(farthest-side ellipse at 10% 0,#333867,#17193b);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.CnGreenGrad{
  overflow: hidden;
  background-color: rgba(5, 90, 23, 0.6) !important;
  background: radial-gradient(farthest-side ellipse at 10% 0,#43A047,#1B5E20);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.CnRedGrad{
  overflow: hidden;
  background-color: rgba(199, 8, 8, 0.6) !important;
  background: radial-gradient(farthest-side ellipse at 10% 0,#D32F2F,#B71C1C);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.gry{
  background-color: rgba(0,0,0,.24) !important;
}

.overGry{
  background-color: hsla(0,0%,100%,.1) !important;
}

.underGry{
  background-color: hsla(0,0%,50%,.1) !important;
}

.tinyText{
  font-size: 0.6em;;
}

.v-slider--horizontal{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.v-stepper--vertical{
  padding-bottom: 0px !important;
}

.v-stepper--vertical .v-stepper__step{
  padding: 5px !important;
}

.OverflowCardY{
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsla(0,0%,50%,.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsla(0,0%,100%,.1);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
