<template>

    <v-card class="pa-4" color="CnBgGrad">
        <p class="text-h6">Settings</p>

        <v-tabs v-model="tab" background-color="gry" centered>
            <v-tab>APIs</v-tab>
            <v-tab>Settings</v-tab>
            <v-tab>Steve</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="transparent px-1 mt-4">
            <v-tab-item>
                <SettingsAPI />
            </v-tab-item>
            <v-tab-item>
                <SettingsGeneral />
            </v-tab-item>
            <v-tab-item>
                <SettingsSteve />
            </v-tab-item>
        </v-tabs-items>

    </v-card>

</template>

<script>
import SettingsAPI from '@/components/widgets/SettingsAPI'
import SettingsGeneral from '@/components/widgets/SettingsGeneral'
import SettingsSteve from '@/components/widgets/SettingsSteve'

export default {
  name: 'SettingsMenu',

  components: {
      SettingsAPI,
      SettingsGeneral,
      SettingsSteve
  },

  data: () => ({
    tab: null,
  }),

  computed:{

    sizes(){
      return this.$store.getters.sizes
    }

  },

  methods:{

  },

}
</script>