<template>
    <div class="fill-height">
      <v-row class="fill-height" no-gutters>
        <v-col cols="7">
                   <v-card v-if="!sizes.coinPerformanceTitle" color="gry" flat tile class="ml-4 px-3 pt-3 pb-2">
            <v-row no-gutters>
              <v-col cols="auto">
                <p v-if="coin" :class="`${sizes.text} font-weight-bold green--text mb-0`">Coin Performance</p>
                <p v-else :class="`${sizes.text} font-weight-bold green--text mb-0`">Pot Performance</p>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn v-if="coin" :small="sizes.smallIcon" color="overGry" @click="coin = !coin">Pot Performance</v-btn>
                <v-btn v-else :small="sizes.smallIcon" color="overGry" @click="coin = !coin">Coin Performance</v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card color="gry" flat tile class="ml-4 px-3 pb-6">
              <summaryCoinPerformance v-if="coin" />
              <summaryPotPerformance v-else />
          </v-card>
        </v-col>

        <v-col cols="5" class="px-3">
          <v-row >
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summarySteve />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryBuyToSell />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryTable />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card color="gry" class="pa-3">
                <summaryBittrex />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


    </div>
</template>

<script>
import summaryCoinPerformance from '@/components/V2/DashBoard/Widgets/summaryCoinPerformance'
import summaryPotPerformance from '@/components/V2/DashBoard/Widgets/summaryPotPerformance'
import summaryTable from '@/components/V2/DashBoard/Widgets/summaryTable'
import summaryBittrex from '@/components/V2/DashBoard/Widgets/summaryBittrex'
import summarySteve from '@/components/V2/DashBoard/Widgets/summarySteve'
import summaryBuyToSell from '@/components/V2/DashBoard/Widgets/summaryBuyToSell'


export default {
  name: 'DesktopDashboardV2Vertical',

  components: {
    summaryCoinPerformance,
    summaryPotPerformance,
    summaryTable,
    summaryBittrex,
    summarySteve,
    summaryBuyToSell
  },

  data: () => ({
    coin: true
  }),

  computed:{
    sizes(){
      return this.$store.getters.sizes
    }

  },
}
</script>
