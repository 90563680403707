<template>
    <component :is="component" />
</template>

<script>
import { mapState } from 'vuex'
import DBChoose from '@/components/V2/DashBoard/DBChoose'
import DBVertical from '@/components/V2/DashBoard/DBVertical'
import DBNormal from '@/components/V2/DashBoard/DBNormal'
import DBWide from '@/components/V2/DashBoard/DBWide'
import DBMobile from '@/components/V2/DashBoard/DBMobile'

export default {
    name: 'Dashboard',

    components: {
        DBChoose,
        DBVertical,
        DBNormal,
        DBWide,
        DBMobile
    },

    data: () => ({

    }),

    computed:{
        ...mapState({
            desktopComponent: state => state.desktopComponent,
            pots: state => state.pots
        }),

        component(){
            return this.$vuetify.breakpoint.smAndDown ? 'DBMobile' : this.desktopComponent
        },
    },

    methods:{

    },

    created(){        
        this.$store.dispatch('getDatal1')
        this.$store.dispatch('getDatal2')
        this.$store.dispatch('getDatal3')
        this.$store.dispatch('setNow')
    }
}
</script>
