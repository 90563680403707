<template>
    <v-row align="center" justify="center" no-gutters>
        <v-col cols="2" class="pr-4">
            <p class="white--text- ml-2 mb-0">Date Range:</p>
           <v-select class="ml-2" v-model="chartRange" :items="rangeOptions" background-color="grey darken-2" label="Chart Range" dense solo></v-select>

            <p class="white--text- ml-2 mb-0">Chart Data:</p>
           <v-select class="ml-2" v-model="chartData" :items="chartDataOptions" background-color="grey darken-2" label="Chart Range" dense solo></v-select>
        </v-col>
        <v-col cols="10">
            <GChart type="LineChart" :data="coinChartData" :options="chartOptions" />
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { GChart } from 'vue-google-charts'
import { DateTime } from 'luxon'

export default {
    name: 'CoinHistoryGraph',

    components: {
        GChart
    },

    data: () => ({
        rangeOptions:[30,10,5],
        chartRange: 30,
        chartDataOptions:['Prices', 'Market Cap', 'Volume'],
        chartData: 'Prices',
         chartOptions:{
            chartArea:{width:"90%"},
            height: 400,
            legend: 'none',
            backgroundColor: { fill:'transparent' },
            hAxis : { 
                textStyle : {
                    fontSize: 8,
                    color: '#ddd', 
                }
            },
            vAxis : { 
                textStyle : {
                    fontSize: 12,
                    color: '#ddd',  
                }
            },
            colors:['#9C27B0']
        }
    }),

    computed:{
        ...mapState({
            currentCoin: state => state.currentCoin,
            currentCoinHistory: state => state.currentCoinHistory
        }),

        coinChartData(){
            if(this.currentCoinHistory){
                let arr = [['Date', 'Price']]
                let t = 'prices'
                if(this.chartData == 'Market Cap'){t = 'market_caps'}
                if(this.chartData == 'Volume'){t = 'total_volumes'}
                const dta = this.arrToDT(this.currentCoinHistory[t])
                arr = [...arr, ...dta]
                return arr
            } else {
                return []
            } 
        }
        
    },

    methods:{
        dtISO(s){
            const dt = DateTime.fromISO(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
            return dt
        },

        arrToDT(a){
            const fa = a.filter(f => DateTime.fromMillis(f[0]) >  DateTime.local().minus({days: this.chartRange}))
            const na = fa.map(i => [DateTime.fromMillis(i[0]).toFormat('dd LLL - HH:mm'), i[1]])
            return na
        },

        coinChart(c){
            let chartData = [['Date', 'Value']]            
            if(this.bittrexSummary){
                const coinData = this.coinData(c)
                coinData.forEach(e => {
                    const d = new Date(e.TimeStamp)
                    const v = parseFloat(e.Value)
                    chartData.push([d, v])
                })
            }
            return chartData 
        },
    }
}
</script>