<template>
        <v-row no-gutters align="center" >
            <v-col cols="12">
                <p :class="`text-right ${sizes.smallText} ${timeFormat} mb-0`">{{ time }}</p>
            </v-col>
        </v-row>
</template>


<script>
// import { DateTime } from 'luxon'

export default {
    name:'PotSummaryTimes',

    props:['pot'],

    data: () => ({

    }),

    computed:{
        time(){
        return `${Math.abs(this.pot.time.days)}d ${Math.abs(this.pot.time.hours)}h ${Math.abs(this.pot.time.minutes).toFixed(0)}m`
        // return `${this.pot.time.days}d ${this.pot.time.hours}h ${this.pot.time.minutes.toFixed(0)}m`
        },

        timeFormat(){
            if(this.pot.potState == 'sell'){
                return Math.abs(this.pot.time.days) > 10 ? 'red--text' : Math.abs(this.pot.time.days) > 5 ? 'amber--text text--darken-2' : Math.abs(this.pot.time.days) < 1 ? 'green--text' : 'teal--text'
            } else if(this.pot.potState == 'buy'){
                return this.pot.cooldown ? 'blue--text' : Math.abs(this.pot.time.days) > 5 ? 'red--text' : Math.abs(this.pot.time.days) < 1 ? 'green--text' : 'orange--text'
            }
        },

        sizes(){
            return this.$store.getters.sizes
        },
    },
}
</script>