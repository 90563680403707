<template>
    <v-card class="grey darken-3 pa-4">
        <p class="text-h6 text-center">Manual Reset Controls</p>
        <p class="grey--text">Clicking Confirm will result in Crypton making a single attempt to sell the pot.</p>

        <v-row no-gutters>
            <v-col cols="3">
                <p class="mb-0">Current Rate:</p>
            </v-col>
            <v-col cols="5">
                <p class="mb-0 text-right">{{ resetParams.ask }}</p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="3">
                <p>Required Rate:</p>
            </v-col>
            <v-col cols="5">
                <p :class="`${resetAllowed ? 'green' : 'red'}--text text-right font-weight-bold`"> {{ resetParams.rate }}</p>
            </v-col>
        </v-row>


        <p class="mt-4">The Parameters that will be submitted are:</p>
        
        <v-row v-if="resetParams" no-gutters>
            <v-col cols="4">
                <p class="mb-0 grey--text">Invested:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.invested }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Stake:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.stake }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Source:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.source }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Rate:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.rate }}</p>
            </v-col>
        </v-row>

        <v-row no-gutters justify="center" class="my-4">
            <v-btn class="primary" @click="onManualResetCoin">Confirm Manual Reset</v-btn>
        </v-row>

        <v-row justify="center" class="mt-6">
            <p :class="`${col}--text`">{{ response }}</p>
            <p class="caption-text grey--text">{{ subResponse }}</p>
        </v-row>

        <v-row>
            <v-spacer />
            <v-btn small color="gry" @click="onClose">Close</v-btn>
        </v-row>
    </v-card>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'ManualCoinReset',

    props:['resetAllowed', 'resetParams'],

    data: () => ({
        response: 'Resting...',
        subResponse: '',
        col: 'white',
        busy: false
    }),

    computed:{
        ...mapState({
            resetResponse: state => state.resetResponse,
            settings: state => state.settings
        }),
    },

    methods:{
        onManualResetCoin(){
            if(this.resetParams){
                if(this.resetAllowed){
                    this.busy = true
                    this.$store.dispatch('getAPIDataV2', {api:this.resetParams.api, mutation:'SET_RESET_RESPONSE', params:this.resetParams.params})
                } else {
                    this.col = 'cyan'
                    this.response = 'It is too shameful to submit a loss to Crypton, sorry!'
                }
            } else {
                this.col = 'red'
                this.response = 'Failed'
                this.subResponse = 'Coin Parameters incomplete...'
            }
        },

        onClose(){
            this.$emit('close')
        }
    },

    watch:{
        resetResponse(v){
            if(v.length > 0){
                this.busy = false
                this.response = v[v.length - 1].Result
                this.subResponse = v[v.length - 1].LogLine
                this.col = this.response == 'Success' ? 'green' : 'red'
            }
        }
    }

}
</script>