import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        // gry: '#161834',
        primary: '#2477ff',
        secondary: '#9C27B0',
        accent: '#81D4FA',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        sell: '#FFEB3B',
        buy: '#9C27B0'
      },
    },
  },
});




// --blue: #2477ff;
// --indigo: #6610f2;
// --purple: #474d84;
// --pink: #e671b8;
// --red: #db2a34;
// --orange: #e49400;
// --yellow: #ffc107;
// --green: #2d8515;
// --teal: #4179cf;
// --cyan: #17a2b8;
// --white: #fff;
// --gray: #798892;
// --gray-dark: #3c484f;
// --primary: #2477ff;
// --secondary: rgba(244,244,245,0.9);
// --success: #2d8515;
// --info: #4179cf;
// --warning: #e49400;
// --danger: #db2a34;
// --light: #f8f9fa;
// --dark: #040620;
// --inverse: #30314e;
// --gray: #595d78;
// --default: #474d84;
// --primary-light: #dee4ee;
// --success-light: #ecfaec;
// --info-light: #f2fafa;
// --warning-light: #fdf7e6;
// --danger-light: #fff2ef;
// --progress-bg: #30314e;