<template>
    <v-card class="grey darken-3 pa-4">
        <p class="text-h6 text-center">Auto Reset Controls</p>
        <p class="grey--text mb-0">Clicking Confirm will result in Crypton making multiple attempts to sell the pot.</p>
        <p class="text-caption grey--text">The attempts will automatically stop if the profit becomes negative.</p>

        <v-row no-gutters>
            <v-col cols="3">
                <p class="mb-0">Current Rate:</p>
            </v-col>
            <v-col cols="5">
                <p class="mb-0 text-right">{{ resetParams.ask }}</p>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="3">
                <p>Required Rate:</p>
            </v-col>
            <v-col cols="5">
                <p :class="`${resetAllowed ? 'green' : 'red'}--text text-right font-weight-bold`"> {{ resetParams.rate }}</p>
            </v-col>
        </v-row>


        <p class="mt-4">The Parameters that will be submitted are:</p>
        
        <v-row v-if="resetParams" no-gutters>
            <v-col cols="4">
                <p class="mb-0 grey--text">Invested:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.invested }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Stake:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.stake }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Source:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.source }}</p>
            </v-col>
            <v-col cols="4">
                <p class="mb-0 grey--text">Rate:</p>
            </v-col>
            <v-col cols="6">
                <p class="mb-0">{{ resetParams.rate }}</p>
            </v-col>
        </v-row>

        <v-row no-gutters justify="center" class="my-4">
            <v-btn v-if="isCancelled" color="secondary" @click="onStart">Confirm Auto Reset</v-btn>
            <v-btn v-else outlined color="secondary" @click="onCancel">Cancel Auto Reset</v-btn>
        </v-row>

        <v-row class="mt-6" justify="center" no-gutters>
            <v-col cols="12">
                <p v-if="retryAttempts > 0" class="text-center">Attempts: {{ retryAttempts }}</p>
            </v-col>
            <v-col cols="12">
                <p :class="`${col}--text text-center mb-0`">{{ response }}</p>
            </v-col>
            <v-col cols="12">
                <p class="text-caption text-center grey--text">{{ subResponse }}</p>
            </v-col>
            <v-col cols="8">
                <v-progress-linear v-if="isDelay" indeterminate height="2" ></v-progress-linear>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-5">
            <v-spacer />
            <v-btn small color="gry" @click="onClose">Close</v-btn>
        </v-row>
    </v-card>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'AutoCoinReset',

    props:['resetAllowed', 'resetParams'],

    data: () => ({
        response: 'Resting...',
        subResponse: '',
        col: 'white',
        isDelay: false,
        delayTimeout: null,
        isCancelled: true,
        retryAttempts: 0
    }),

    computed:{
        ...mapState({
            resetResponse: state => state.resetResponse,
            settings: state => state.settings
        }),
    },

    methods:{

        isSubmissionAllowed(){
            if(!this.isCancelled){
                if(this.resetAllowed){
                    this.response = 'Submitting'
                    this.subResponse = ''
                    this.col="white"
                    this.onSubmit()
                } else {
                    this.response = 'Checking'
                    this.subResponse = ''
                    this.col="white"
                    this.$store.dispatch('writeResetResponse', {Result: 'Not Ready to Sell Yet..', LogLine:'The Current Rate does not meet the Required Rate.'})
                    this.onDelay()
                }
            }
        },

        onSubmit(){
            this.$store.dispatch('getAPIDataV2', {api:this.resetParams.api, mutation:'SET_RESET_RESPONSE', params:this.resetParams.params})
        },

        onDelay(){
            this.isDelay = true
            const delayTimeout = setTimeout(() => {
                this.isDelay = false
                console.log('delay')
                this.isSubmissionAllowed()
            }, this.settings.autoResetInverval)
            this.delayTimeout = delayTimeout
        },

        onStart(){
            this.isCancelled = false
            this.isSubmissionAllowed()
        },

        onCancel(){
            this.isCancelled = true
            clearTimeout(this.delayTimeout)
            this.isDelay = false
        },

        onClose(){
            this.onCancel()
            this.$emit('close')
        }


    },

    watch:{
        resetResponse(v){
            if(v.length > 0){
                this.retryAttempts = v.length
                this.response = v[v.length - 1].Result
                this.subResponse = v[v.length - 1].LogLine
                this.col = this.response == 'Success' ? 'green' : 'red'
                if(this.response == "Failure"){
                    console.log(v)
                    this.onDelay()
                }
            }
        }
    }

}
</script>