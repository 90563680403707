<template>
    <div>
        <v-row v-if="bss" no-gutters>
            <v-col cols="12">
                <v-row no-gutters>
                    <v-col cols="12">

                            <v-row no-gtters>
                                <v-col cols="12">
                                    <p class="text-center font-weight-bold primary--text">Today</p>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Buy's</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="5">
                                    <p class="text-right text-h5 mb-0">${{ bss.buyValue }}</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">in</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="text-right text-h5 mb-0">{{ bss.buyCount }}</p>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2">Sells's</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="5">
                                    <p class="text-right text-h5">${{ bss.sellValue }}</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2">in</p>
                                </v-col>
                                <v-col cols="2">
                                    <p class="text-right text-h5">{{ bss.sellCount }}</p>
                                </v-col>
                            </v-row>
                    </v-col>
                </v-row>

                <v-divider class="mt-2 mb-4" />

                <v-row  no-gutters>
                    <v-col cols="12">
                            <v-row no-gtters>
                                <v-col cols="12">
                                    <p class="text-center font-weight-bold secondary--text">Profit</p>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">USD Today</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">$ {{ bss.balanceToday }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">USDT Today</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">$ {{ bss.usdtBalanceToday }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Total</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">$ {{ bss.balanceValue }}</p>
                                </v-col>
                            </v-row>
                    </v-col>
                </v-row>

                <v-divider class="mt-2 mb-4" />

                <v-row  no-gutters>
                    <v-col cols="12">
                            <v-row no-gtters>
                                <v-col cols="12">
                                    <p class="text-center font-weight-bold accent--text">Crypton</p>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Stake</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">$ {{ bss.botStakeValue }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Coins</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">{{ bss.coinCount }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Pots</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 mb-0">{{ potCount }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <p class="text-caption grey--text mt-2 mb-0">Float</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 green--text mb-0">$ {{ bss.botAvailableFloat }}</p>
                                </v-col>
                                <v-spacer />
                                <v-col cols="auto">
                                    <p class="text-right text-h5 blue--text mb-0">$ {{ bss.botCooldownValue }}</p>
                                </v-col>
                            </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12">
                <p class="">Getting Summary</p>
                <v-progress-linear indeterminate height="1" class="mx-4" color="primary" />
            </v-col>
         </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BittrexSummary',

    computed:{
        ...mapState({
            settings: state => state.settings,
        }),

        bss(){
            return this.$store.getters.BittrexSummarySmallFormatted
        },

        potCount(){
            return this.$store.getters.potCount.active
        }
    }
}
</script>