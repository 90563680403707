<template>
  <div>      
    <v-row>
      <v-col cols="3">
          <v-row>
            <v-col cols="auto">
              <v-img :src="currentCoinData.image.large" :height="iconSize" :width="iconSize" contain class="ml-8"/>
            </v-col>
            <v-col cols="auto">
              <p class="white--text text-h4 mb-0">{{ currentCoin.name }}</p>
              <p class="white--text text-caption ml-2">{{ currentCoin.symbol.toUpperCase() }} Dashboard</p>
            </v-col>
          </v-row>
      </v-col>
      <v-col cols="9">
          <MarqueeText class="mt-5" :duration="10">
            <template>
              <div v-for="(m, i) in mText" :key="i" class="d-inline">
                <span class="ml-4 mr-2">{{ m.txt }}</span><div :class="`${m.css} px-2 d-inline rounded-lg`">{{ m.val }}</div>
              </div>
            </template>
          </MarqueeText>
          <MarqueeText class="" :duration="40">
            <template>
              <div v-for="p in currentPrice" :key="p[0]" class="d-inline">
                <span class="ml-4 mr-2">{{ p[0] }}:</span><div :class="`${p[1] > 0 ? 'green darken-2' : 'red darken-2'} px-2 d-inline rounded-lg`">{{ p[1].toFixed(4) }}</div>
              </div>
            </template>
          </MarqueeText>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-btn text small class="ml-5" color="accent" @click="onDashboard">Home</v-btn>
      <v-btn text small class="ml-6" @click="abtD = !abtD">About</v-btn>
      <v-btn text small class="ml-2" @click="abtL = !abtL">Links</v-btn>
      <v-spacer />
      <p class="text-caption accent--text mr-3 mt-1">{{ dtISO(currentCoinData.last_updated) }}</p>
    </v-row>

    <v-row no-gutters>
      <v-col cols="8">
        <HistoryGraph />
      </v-col>
      <v-col cols="4" class="pr-4">
        <CoinControls class="mb-8" />
        <CoinOverview />
      </v-col>
    </v-row>
      
    <v-row no-gutters>
      <v-col cols="6">
        <CoinPots @potDetail="onPotDetails" />
      </v-col>
      <v-col cols="6">
        <PotDetails :p="potDetails"/>
      </v-col>
    </v-row>


      <v-dialog v-model="abtD" width="800">
        <v-card class="gry">
          <p class="text-h4 text-center pt-4">About {{ currentCoin.name }}</p>
          <v-card-text><p v-html="currentCoinData.description.en"></p></v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="abtL" width="800">
        <Links :name="currentCoin.name" :links="currentCoinData.links" />
      </v-dialog>
    </div>



</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import MarqueeText from 'vue-marquee-text-component'
import Links from '@/components/Coin/parts/Links'
import HistoryGraph from '@/components/Coin/parts/HistoryGraph'
import CoinControls from '@/components/Coin/parts/CoinControls'
import CoinOverview from '@/components/Coin/parts/CoinOverview'
import CoinPots from '@/components/Coin/parts/CoinPots'
// import COverview from '@/components/Coin/parts/COverview'
import PotDetails from '@/components/Coin/parts/PotDetails'

export default {
    name: 'CoinDetails',

    components: {
      MarqueeText,
      Links,
      HistoryGraph,
      CoinOverview,
      CoinControls,
      CoinPots,
      PotDetails,
    },

    data: () => ({
        iconSize: 60,
        abtD: false,
        abtL: false,
        potDetails: null
    }),

    computed:{
        ...mapState({
            currentCoin: state => state.currentCoin,
            currentCoinData: state => state.currentCoinData,
            currentCoinHistory: state => state.currentCoinHistory
        }),

        mTextItems(){
          return [
            {text: 'Rank:', val:this.currentCoinData.coingecko_rank, trigger:200},
            {text: 'Score:', val:this.currentCoinData.coingecko_score, trigger:50},
            {text: 'FB Likes:', val:this.currentCoinData.community_data.facebook_likes, trigger:50},
            {text: 'Reddit Subs:', val:this.currentCoinData.community_data.reddit_subscribers, trigger:100000},
            {text: 'Twitter Followers:', val:this.currentCoinData.community_data.twitter_followers, trigger:100000},
            {text: 'Dev Forks:', val:this.currentCoinData.developer_data.forks, trigger:500},
            {text: 'Dev Stars:', val:this.currentCoinData.developer_data.stars, trigger:2500},
            {text: 'Dev Score:', val:this.currentCoinData.developer_score, trigger:80},
            {text: 'Mkt Cap:', val:this.currentCoinData.market_data.market_cap.usd, trigger:100000},
            {text: 'Mkt Cap Rank:', val:this.currentCoinData.market_cap_rank, trigger:30},
            {text: 'Mkt Cap Rank:', val:this.currentCoinData.public_interest_stats.alexa_rank, trigger:15000},
            {text: 'Mkt Cap Rank:', val:this.currentCoinData.public_interest_stats.bing_matches, trigger:15000},

          ]
        },

        mText(){
          const c = this.currentCoinData
          let sArr = []
          this.mTextItems.forEach(m => {
            if(m.val){
               sArr.push(this.mTextObj(m.text, m.val, m.trigger))
            }
          })
          return sArr
        },

        currentPrice(){
          return Object.entries(this.currentCoinData.market_data.price_change_24h_in_currency)
        }


        
    },

    methods:{
      onDashboard(){
        this.$emit('onDashboard')
        this.$router.push('/dashboard').catch(e => console.log(e))
      },

      mTextCss(s, tVal){
        return s > tVal ? 'green darken-2' : 'red darken-2'
      },

      mTextObj(txt, val, trigger){
        return {txt, val, css:this.mTextCss(val, trigger)}
      },

      dtISO(s){
        const dt = DateTime.fromISO(s).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
        return dt
      },

      onPotDetails(p){
        this.potDetails = p
      },

      onCoinPanel(pn){
        this.coinPanelComponent = pn
        if(pn == 'CCoinControls'){this.potDet = null}
      }
    },

    created(){
      
    },

    watch:{

    }


}
</script>
